import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { setAccessToken } from "../utils/auth";
import { cancelMembership, getMembershipList } from "../utils/api";
import { MembershipCancelItem } from "../utils/api.d";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import moment from "moment-timezone";
import Button from "@mui/material/Button";

moment.tz.setDefault("Asia/Seoul");
moment.locale("ko");

interface Data {
  _id: string;
  paymentId: string;
  username: string;
  email: string;
  amount: number;
  paidAt: string;
  premiumReadCount: number;
  status: string;
}

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  align?: "right" | "left" | "center";
}
const headCells: readonly HeadCell[] = [
  {
    id: "paymentId",
    disablePadding: true,
    label: "결제 아이디",
  },
  {
    id: "username",
    disablePadding: true,
    label: "이름",
  },
  {
    id: "email",
    disablePadding: false,
    label: "이메일",
  },
  {
    id: "amount",
    disablePadding: false,
    label: "결제 금액",
  },
  {
    id: "paidAt",
    disablePadding: false,
    label: "결제일",
  },
  {
    id: "premiumReadCount",
    disablePadding: false,
    label: "프리미엄 조회 수",
  },
  {
    id: "status",
    disablePadding: false,
    label: "상태",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align || "left"} padding={"normal"} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const MembershipPayment = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [list, setList] = useState<MembershipCancelItem[]>([]);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("paidAt");
  const [selected, setSelected] = useState<readonly string[]>([]);

  const [cancelLoading, setCancelLoading] = useState(false);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // const newSelected = rows.map((n) => n.id);
      // setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    constructUserStat();
  }, []);

  const constructUserStat = async () => {
    const token = await getAccessTokenSilently();
    setAccessToken(token);

    const list = await getMembershipList();
    setList(list.cancelList);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleCancelRequest = async (userId: string, paymentId: string) => {
    setCancelLoading(true);
    const res = await cancelMembership(userId, paymentId);
    console.log(res);

    if (res.isOk) {
      setList((prev) => {
        const newList = [...prev];
        const target = newList.find((item) => item.paymentId === paymentId);
        if (!target) return prev;
        target.status = "canceled";
        return newList;
      });
    } else {
      alert(res.message);
    }
    setCancelLoading(false);
  };

  return (
    <div style={{ width: "1300px", paddingTop: "40px" }} className="membership-payment">
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, fontSize: "12px" }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={list.length}
              />
              <TableBody>
                {list.map((row, index) => {
                  const isItemSelected = selected.includes(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      {/* <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </TableCell> */}
                      <TableCell component="th" id={labelId} scope="row">
                        {row.paymentId}
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        {row.username}
                      </TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right">{row.amount.toLocaleString()}</TableCell>
                      <TableCell align="right">{moment(row.payment.paidAt).format("YYYY-MM-DD(ddd) HH:mm")}</TableCell>
                      <TableCell align="right">{row.premiumReadCount.toLocaleString()}</TableCell>
                      <TableCell align="center" style={{ color: row.status === "canceling" ? "red" : "black" }}>
                        {row.status === "canceling" ? "취소 요청 대기" : "취소 완료"}
                        {row.status === "canceling" && (
                          <Button
                            disabled={cancelLoading}
                            variant="outlined"
                            style={{ marginLeft: "10px", cursor: cancelLoading ? "not-allowed" : "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCancelRequest(row.userId, row.paymentId);
                            }}
                          >
                            취소 하기
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
};

export default MembershipPayment;
