import { FC, useEffect, useState } from "react";
import { Box, Container, Typography, TextField, Checkbox, FormGroup, FormControlLabel, FormLabel, InputLabel, Paper, MenuItem, FormControl, FormHelperText, Button } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';

import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

import {Link, useHistory, useParams} from "react-router-dom";
// import { addNewContact } from "../service/api";
import { CheckboxWithLabel } from "formik-material-ui";
// import { ObjectID } from "mongodb";
import { FormikProps, useFormik, useField, useFormikContext, Form, Field  } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {array} from "yup";
import {getPushNotification, postPushNotification, putPushNotification} from "../../utils/api";
import moment from "moment-timezone";

interface AddNotificationProps {
    taskName: string;
    targetUserEmails: string;
    targetUserTypes: Array<string>;
    targetDate: string;
    pushType: string;
    title: string;
    content: string;
    contentId: string;
    activated: Boolean;
}

interface RouteParams {
    id: string;
}

interface Options {
    label: string,
    value: string
}

const initialData: any = {
    taskName: "",
    targetUserEmails: "",
    targetUserTypes: [],
    targetDate: "",
    pushType: "",
    title: "",
    content: "",
    contentId: "",
    activated: false,
};

// const PushNotificationAdd: FC = () => {
export const PushNotificationAdd = () => {
    moment.tz.setDefault("Asia/Seoul");
    const history = useHistory();

    const { id } = useParams<RouteParams>();
    console.log('id', id);

    const mode = id ? 'edit' : 'add';
    const [pushNotificationDetails, setPushNotificationDetails] = useState(initialData);


    const USER_TYPES: Array<Options> = [
        {
            "label": "D",
            "value": "D"
        },
        {
            "label": "E1",
            "value": "E1"
        },
        {
            "label": "E2",
            "value": "E2"
        },
        {
            "label": "E3",
            "value": "E3"
        },
        {
            "label": "E4",
            "value": "E4"
        },
        {
            "label": "E5",
            "value": "E5"
        },
        {
            "label": "B",
            "value": "B"
        },
        {
            "label": "R",
            "value": "R"
        },
        {
            "label": "C",
            "value": "C"
        }
    ];

    const PUSH_TYPES: Array<Options> = [
        {
            "value": "article",
            "label": "아티클 리스트"
        },
        {
            "value": "article_view",
            "label": "아티클 상세"
        },
        {
            "value": "newsletter",
            "label": "뉴스레터 상세"
        },
        {
            "value": "subscription",
            "label": "구독리스트"
        },
        {
            "value": "curation",
            "label": "큐레이션 리스트"
        },
        {
            "value": "profile",
            "label": "프로필"
        },
        {
            "value": "feed",
            "label": "피드리스트"
        },
        {
            "value": "url",
            "label": "URL"
        },
        {
            "value": "notice",
            "label": "앱실행"
        }
    ];

    const loadPushNotificationDetails = async () => {
        if(!id) return;
        const resp = await getPushNotification(id);
        console.log('res', resp );
        setPushNotificationDetails(resp);
        // formik.values = resp;
        formik.values.taskName = resp.taskName;
        formik.values.targetUserEmails = resp.targetUserEmails;
        formik.values.targetUserTypes = resp.targetUserTypes;
        formik.values.targetDate = moment(resp.targetDate).format('YYYY-MM-DDTHH:mm');
        formik.values.pushType = resp.pushType;
        formik.values.title = resp.title;
        formik.values.content = resp.content;
        formik.values.contentId = resp.contentId;

        return false;
    };

    const refreshData = async () => {
        // e.preventDefault();
        // e.stopPropagation();
        await loadPushNotificationDetails();
    }

    useEffect(() => {

        console.log('formik.values', formik.values);

        async function fetchAndSetUser() {
            await loadPushNotificationDetails();
            // setUser(data);
        }
        if(mode === 'edit') {
            fetchAndSetUser();
        }

        // document.title = "Add New Notification | Notification Manager";
        //
        // const initData = {...initValues, title: 'test1'};
        // setInitValues(initData);
        // if(mode === 'edit') {
        //     setTimeout(()=> {
        //         loadPushNotificationDetails();
        //     }, 200);
        // }

        // formik.values.taskName = 'ddddd';

    }, []);



    const formik: FormikProps<AddNotificationProps> = useFormik<AddNotificationProps>({
        enableReinitialize: true,
        initialValues: pushNotificationDetails
        //     || {
        //     taskName: "",
        //     targetUserEmails: "",
        //     targetUserTypes: [],
        //     targetDate: "",
        //     pushType: "",
        //     title: "",
        //     content: "",
        //     contentId: "",
        // }
        ,

        validationSchema: Yup.object({
            taskName: Yup.string().required("작업명은 필수입니다."),
            targetUserEmails: Yup.string().optional(),
            targetUserTypes: Yup.array().optional(),
            targetDate: Yup.string().required("발송일시는 필수입니다."),
            pushType: Yup.string().required("푸시유형은 필수입니다."),
            title: Yup.string().required("알림 제목은 필수입니다."),
            content: Yup.string().required("알림 내용은 필수입니다."),
            contentId: Yup.string().optional(),
        }),

        onSubmit: async (values) => {
            try {
                // await addNewContact(values);
                if(mode!=='edit') {
                    await postPushNotification({...values, status: 'waiting', activated: false, _id: ''});
                }else{
                    await putPushNotification(id, {...values});
                }

                // console.log('content', JSON.stringify(content));
                toast.success("저장완료!", {
                    duration: 1500,
                    style: {
                        fontFamily: "sans-serif",
                    },
                });
                history.push("/push-notifications");
            } catch (error) {
                toast.error("Error", {
                    duration: 1500,
                    style: {
                        fontFamily: "sans-serif",
                    },
                });
            }
        },
    });

    if(id){
        // loadPushNotificationDetails();
    }

    // if(pushNotificationDetails.taskName === '') return <>111111</>;

    console.log('mode', mode);

    console.log('formik.values', formik.values);

    return (
        <Box>
            <form noValidate onSubmit={formik.handleSubmit}>
                <Container maxWidth="md">
                    <Box sx={{ mb: 4, cursor: "pointer" }}>
                        <Box sx={{ my: 4 }}>
                            <Box
                                component={Link}
                                to="/push-notifications"
                                color="textPrimary"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    textDecoration: "none",
                                }}
                            >
                                <ArrowBackOutlinedIcon
                                    fontSize="small"
                                    sx={{ mr: 1, color: "#6B7280" }}
                                />
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: "#000",
                                        fontSize: "15px",
                                        "&:hover": {
                                            textDecoration: "underline",
                                        },
                                    }}
                                >
                                    목록으로
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Typography variant="h4" sx={{ fontSize: "30px" }}>
                        푸시알림 발송 {mode === 'add' ? '추가' : '수정'}
                    </Typography>

                    <TextField
                        fullWidth
                        label="작업명"
                        name="taskName"
                        required
                        error={Boolean(formik.touched.taskName && formik.errors.taskName)}
                        helperText={formik.touched.taskName && formik.errors.taskName}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.taskName || ""}
                        sx={{
                            mt: 4,
                        }}
                        // inputProps={{
                        //     style: { textTransform: "uppercase" },
                        // }}
                    />

                    <TextField
                        fullWidth
                        label="사용자 이메일 리스트(콤마로 구분)"
                        name="targetUserEmails"
                        required
                        error={Boolean(formik.touched.targetUserEmails && formik.errors.targetUserEmails)}
                        helperText={formik.touched.targetUserEmails && formik.errors.targetUserEmails}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.targetUserEmails}
                        sx={{
                            mt: 3,
                        }}
                        multiline
                        rows={4}
                        // inputProps={{
                        //     style: { textTransform: "uppercase" },
                        // }}
                    />
                    <Paper variant="outlined" style={{padding: '20px', marginTop: '24px'}}>
                        <FormLabel>사용자유형</FormLabel>

                        <FormGroup row>
                            {USER_TYPES && USER_TYPES.map(option=> {
                                return <FormControlLabel key={option.value} sx={{mt: 1}} control={<Checkbox name='targetUserTypes' checked={formik.values.targetUserTypes.includes(option.value)} onChange={formik.handleChange} value={option.value} />} label={option.label} style={{marginRight: '30px'}} />;
                            })}
                        </FormGroup>
                    </Paper>

                    <TextField
                        id="datetime-local"
                        label="발송일시"
                        name="targetDate"
                        required
                        type="datetime-local"
                        // defaultValue="2017-05-24T10:30"
                        sx={{mt: 3}}
                        InputLabelProps={{
                            shrink: true,
                        }}

                        // value={formik.values.targetDate}
                        // targetDate를  "2017-05-24T10:30" 형식으로 바꿔줘야함
                        value={formik.values.targetDate ? moment(formik.values.targetDate).format('YYYY-MM-DDTHH:mm') : ''}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.targetDate && formik.errors.targetDate)}
                        helperText={formik.touched.targetDate && formik.errors.targetDate}
                    />

                    <FormControl sx={{mt: 3}}>
                        <InputLabel id="demo-simple-select-helper-label">푸시유형</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"

                            // value={30}
                            label="푸시유형"
                            name="pushType"
                            value={formik.values.pushType}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.pushType && formik.errors.pushType)}
                            // helperText={formik.touched.pushType && formik.errors.pushType}
                            // helperText={formik.touched.pushType && formik.errors.pushType}
                        >
                            {/*<MenuItem value="">*/}
                            {/*    <em>None</em>*/}
                            {/*</MenuItem>*/}
                            {PUSH_TYPES && PUSH_TYPES.map(opt=> {
                                return <MenuItem key={opt.value} value={opt.value}>{opt.label} ({opt.value})</MenuItem>
                            })}
                            {/*<MenuItem value={10}>Ten</MenuItem>*/}
                            {/*<MenuItem value={20}>Twenty</MenuItem>*/}
                            {/*<MenuItem value={30}>Thirty</MenuItem>*/}
                        </Select>
                        <FormHelperText>{formik.touched.pushType && formik.errors.pushType}{formik.values.pushType === '' && !formik.errors.pushType ? '푸시유형을 선택해주세요' : ''}</FormHelperText>
                    </FormControl>


                    <TextField
                        fullWidth
                        label="푸시 제목"
                        name="title"
                        required
                        error={Boolean(formik.touched.title && formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        sx={{
                            mt: 4,
                        }}
                        // inputProps={{
                        //     style: { textTransform: "uppercase" },
                        // }}
                    />

                    <TextField
                        fullWidth
                        label="푸시 내용"
                        name="content"
                        required
                        error={Boolean(formik.touched.content && formik.errors.content)}
                        helperText={formik.touched.content && formik.errors.content}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.content}
                        sx={{
                            mt: 4,
                        }}
                        // inputProps={{
                        //     style: { textTransform: "uppercase" },
                        // }}
                        multiline
                        rows={4}
                    />

                    <TextField
                        fullWidth
                        label="컨덴츠 ID"
                        name="contentId"
                        required
                        error={Boolean(formik.touched.contentId && formik.errors.contentId)}
                        helperText={formik.touched.contentId && formik.errors.contentId}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.contentId}
                        sx={{
                            mt: 4,
                        }}
                        // inputProps={{
                        //     style: { textTransform: "uppercase" },
                        // }}
                    />

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            size="large"
                            variant="contained"
                            type="submit"
                            sx={{ width: "50%", mt: 4 }}
                        >
                            푸시알림 저장
                        </Button>
                        <Button
                            size="large"
                            variant="contained"
                            type="submit"
                            sx={{ width: "50%", mt: 4 }}
                            // onClick={refreshData}
                            onClick={() => {refreshData();return false;}}
                        >
                            새로고침
                        </Button>
                    </Box>
                </Container>
            </form>
        </Box>
    );
};

interface params {
    options: Array<Options>,
    title: string,
    name: string,
    // showSelectAll: boolean,
    row: boolean,
    props: any
}

const CheckboxList = (params: params) => {
    const [field] = useField(params.name);
    const { value } = field;
    const { setFieldValue } = useFormikContext();

    // interface Option {
    //     label: String,
    //     value: String
    // }

    return (
        <FormControl component="fieldset">
            {params.title && <FormLabel component="legend">{params.title}</FormLabel>}
            <FormGroup row={params.row}>
                {params.options.map((opt: Options) => (
                    <FormControlLabel
                        {...field}
                        control={
                            <Checkbox value={opt.value} checked={value.includes(opt.value)} />
                        }
                        // key={opt.value}
                        label={opt.label}
                    />
                ))}
                {/*{showSelectAll && (*/}
                {/*    <FormControlLabel*/}
                {/*        label="Select All"*/}
                {/*        control={*/}
                {/*            <Checkbox*/}
                {/*                onChange={e => {*/}
                {/*                    const vals = e.target.checked*/}
                {/*                        ? options.map(opt => opt.value)*/}
                {/*                        : [];*/}
                {/*                    setFieldValue(name, vals);*/}
                {/*                }}*/}
                {/*            />*/}
                {/*        }*/}
                {/*    />*/}
                {/*)}*/}
            </FormGroup>
        </FormControl>
    );
};

// export default PushNotificationAdd;
