import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {getUserList, putUser} from '../utils/api';
import { setAccessToken } from '../utils/auth';
import { MainList } from './MainList';
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from "@mui/icons-material/Refresh";
import Pagination from "@mui/material/Pagination";
const columnInfo = [
  {
    label: "#",
    name: "orderNo",
    notSortable: true,
    style: { minWidth: 80 },
  },
  {
    label: "가입일시",
    name: "createdAt",
    isAscending: false,
    style: { minWidth: 110 },
  },
  {
    label: "이메일",
    name: "email",
    isAscending: true,
    style: { minWidth: 80 },
  },
  {
    label: "닉네임",
    name: "username",
    isAscending: true,
    style: { minWidth: 80 },
  },
  {
    label: "헤이버니 이메일 ID",
    name: "nickname",
    isAscending: true,
    style: { minWidth: 180 },
  },
  {
    label: "삭제",
    name: "dateOfBirth",
    isAscending: true,
    style: { minWidth: 110 },
  },
  {
    label: "가입 형태",
    name: "identities",
    isAscending: true,
    style: { minWidth: 100 },
  },
  {
    label: "가입 플랫폼",
    name: "signupPlatform",
    isAscending: true,
    style: { minWidth: 100 },
  },
  {
    label: "탈퇴여부",
    name: "isWithdrawn",
    isAscending: true,
    style: { minWidth: 110 },
  },
  {
    label: "유형",
    name: "userType",
    isAscending: true,
    style: { minWidth: 100 },
  },
  {
    label: "구독한 뉴스레터 수",
    name: "numSubscribedNewsletters",
    isAscending: true,
    style: { minWidth: 150 },
  },
  {
    label: "구독 중지한 뉴스레터 수",
    name: "numBlockedNewsletters",
    isAscending: true,
    style: { minWidth: 180 },
  },
  {
    label: "삭제",
    name: "myNewsletterChannelIds",
    style: { minWidth: 300, color: "red" },
    isTextEditable: true,
    // isMultipleSelectable: true,
  },
  // {
  //   label: '읽음 갯수',
  //   name: 'numRead',
  //   isAscending: true,
  //   style: { minWidth: 96 },
  // },
  // {
  //   label: '안읽음 갯수',
  //   name: 'numUnread',
  //   isAscending: true,
  //   style: { minWidth: 100 },
  // },
];

let userList: any = [];
export const UserList = () => {
  const [items, setItems] = useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(10);

  const changePage = (pg: number = 1) => {

    currentPage = pg;
    setPage(pg);

    const limitinput = document.getElementById('input_page_limit');
    if(limitinput instanceof HTMLInputElement){
      limit = limitinput.value;
    }

    const searchinput = document.getElementById('input_search');
    if(searchinput instanceof HTMLInputElement){
      search = searchinput.value;
    }

    setTimeout(()=> {
      constructList(true);
    }, 100);

  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    // setPage(value);
    changePage(value);

  };

  let currentPage = 1;
  let limit = '100';
  let search = '';

  const constructList = async (isReload: boolean = false) => {
    if (isReload || userList.length === 0) {
      // if(isReload) {
      //   setItems([]);
      // }
      setLoading(true);
      setAccessToken(await getAccessTokenSilently());
      const userDocs = await getUserList({params: {page: currentPage, limit: limit, search: search}});
      userList = userDocs.docs || userDocs;
      // userList = await getUserList({params: {page: currentPage, limit: limit, search: search}});
      // const result = await getUserList();
      // userList = result.map((user: any, index: number) => {
      //   return { ...user, orderNo: index + 1 };
      // });

      let userCount = userDocs.total-(userDocs.pagination.page-1)*userDocs.pagination.limit;
      console.log("userCount", userCount);

      let calcPage: number = Math.floor(userDocs.total / userDocs.pagination.limit);
      // calcPage = parseInt(calcPage);
      if(userDocs.total % userDocs.pagination.limit > 0){
        // console.log('dddddd');
        calcPage++;
      }
      setTotalPage(calcPage);

      userList = userDocs.docs.map((user: any, index: number) => {
        return { ...user, orderNo: userCount - index };
      });

    }

    // const userCount = userList.length;
    // console.log("userCount", userCount);

    setItems(
      userList.map((item: any, index: number) => {
        return {
          ...item,
          orderNo: item.orderNo,
          isWithdrawn: item.isWithdrawn ? 'Yes' : '-',
          // dateOfBirth: moment(item.dateOfBirth).format('YYYY.MM.DD'),
          dateOfBirth: item.dateOfBirth ? moment(item.dateOfBirth).format('YYYY.**.**') : '-',
          createdAt: moment(item.createdAt).format('YYYY.MM.DD HH:mm:ss'),
          channelNames:
              item.channels?.map((channel: any) => {
                // const index = metadataList.findIndex((meta: any) => meta._id === metaId);
                // if (index === -1) {
                console.log('index: ', index, 'index: ',channel.title);
                // }
                return channel.title;
                // return {
                //     _id: metaId,
                //     mataName: metadataList[index]?.title,
                //     // mataName: 'item',
                // };
                // return item._id;
              }) || [],
        };
      })
    );

    setLoading(false);
  };

  const submit = (e: any) => {
    console.log(e.keyCode);

    if (e.keyCode === 13) {
      e.preventDefault();
      changePage(1);
      // e.stopImmediatePropagation();
      //Do your stuff...
    }

    // if(e.keyCode===13){
    //   changePage(1);
    //   return false;
    // }
  }

  useEffect(() => {
    constructList();
  }, []);

  const sort = (col: any) => {
    console.log('sort!');
    const index = columnInfo.findIndex((e) => e.name === col.name);
    columnInfo[index].isAscending = !columnInfo[index].isAscending;
    const sortedItems = [...userList].sort((a: any, b: any) => {
      if (typeof a[col.name] === 'number' || typeof a[col.name] === 'boolean') {
        return columnInfo[index].isAscending ? a[col.name] - b[col.name] : b[col.name] - a[col.name];
      }

      if (!a[col.name]) {
        a[col.name] = '';
      }
      if (!b[col.name]) {
        b[col.name] = '';
      }

      return columnInfo[index].isAscending ? a[col.name].localeCompare(b[col.name]) : b[col.name].localeCompare(a[col.name]);
    });
    userList = sortedItems;
    // setItems(sortedItems);
    constructList();
  };

  const handleInputSubmit = async (id: string, name: string, value: any) => {
    const index = userList.findIndex((newsletter: any) => newsletter._id === id);
    if(name==='myNewsletterChannelIds') {
      value = value.split('\n');
      value = value.map((item: any)=> item.trim()).filter((item: any)=> item!=='');

      userList[index][name] = value;
      console.log(id, name, value);
      constructList();
      await putUser(id, { [name]: value });
    }

    // userList[index][name] = value;
    // console.log(id, name, value);
    // constructList();
    // await putUser(id, { [name]: value });

  };

  return (
    <div>
      <h1>회원 리스트</h1>
      페이지당 개수 : <input id='input_page_limit' type='text' defaultValue={limit} /><br/>
      이메일&닉네임 : <input id='input_search' type='text' defaultValue={search} onKeyUp={(e)=>submit(e)} />
      <button onClick={e => changePage(1)} style={{marginLeft: '10px'}}> 적용</button>
      <div style={{padding: '8px'}}></div>
      <Pagination count={totalPage} page={page} onChange={handlePageChange}  variant="outlined" color="secondary"/>

      <MainList onSortToggle={sort} onInputSubmit={handleInputSubmit} columnInfo={columnInfo} listData={items} buttons={[]} />
      <Box sx={{ '& > :not(style)': { m: 1 } }} style={{position: 'fixed', top: '70px', left: '950px'}}>
        <Fab color="primary" aria-label="refresh">
          <RefreshIcon onClick={() => constructList(true)} />
        </Fab>
        {loading && (
            <CircularProgress
                size={68}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: -6,
                  left: -6,
                  zIndex: 1,
                }}
            />
        )}
      </Box>
    </div>
  );
};
