import { useAuth0 } from '@auth0/auth0-react';
import { FormControlLabel, Switch, TextField } from '@material-ui/core';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import qs from 'qs';
import { RouteComponentProps } from 'react-router-dom';
import { FileUploader } from "file-uploader-react-component";
import {getNewsletter, getNewsletterList, putNewsletter} from '../utils/api';
import { accessToken, setAccessToken } from '../utils/auth';
import { MainList } from './MainList';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Fab from "@mui/material/Fab";
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import RefreshIcon from "@mui/icons-material/Refresh";
// import MenuBook from '@mui/icons-material/MenuBook';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ignoreStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const columnInfo = [
  {
    label: "번호",
    name: "orderNo",
    isAscending: false,
    style: { minWidth: 120 },
  },
  {
    label: "제목",
    name: "title",
    isAscending: false,
    isTextEditable: true,
    style: { minWidth: 200 },
  },
  {
    label: "설명",
    name: "shortDescription",
    style: { minWidth: 200 },
    isAscending: false,
    isTextEditable: true,
  },
  {
    label: "언어",
    name: "newsletterLanguage",
    style: { minWidth: 160 },
    isAscending: false,
    isTextEditable: true,
  },
  {
    label: "발송 주소 모음",
    name: "metaIds",
    style: { minWidth: 400 },
    isAscending: false,
    isTextEditable: true,
  },
  {
    label: "ID",
    name: "_id",
    style: { maxWidth: 220 },
    isAscending: false,
  },
  {
    label: "대표 뉴스레터 ID",
    name: "parentMetaGroupId",
    style: { maxWidth: 200 },
    isAscending: false,
  },
  {
    label: "검증",
    name: "verified",
    isAscending: false,
  },
  {
    label: "노출 (대표 뉴스레터)",
    name: "visible",
    isAscending: false,
  },
  {
    label: "노출일시",
    name: "visibledAt",
    style: { maxWidth: 200, minWidth: 150 },
    isAscending: false,
    isTextEditable: false,
  },
  {
    label: "구독 제외",
    name: "ignore",
    style: { minWidth: 60, maxWidth: 60, width: "60px" },
    isAscending: false,
    isTextEditable: false,
  },
  {
    label: "웰컴 메일",
    name: "welcomeEmailExisted",
    style: { minWidth: 60, maxWidth: 60, width: "60px" },
    isAscending: false,
    isTextEditable: false,
  },
  {
    label: "확인 메일",
    name: "confirmEmailExisted",
    style: { minWidth: 60, maxWidth: 60, width: "60px" },
    isAscending: false,
    isTextEditable: false,
  },
  {
    label: "구독 URL",
    name: "subscribeLink",
    style: { maxWidth: 200, minWidth: 150 },
    isAscending: false,
    isTextEditable: true,
  },
  {
    label: "대표 이미지",
    name: "thumbnails",
    isAscending: false,
    style: { maxWidth: 200 },
    isTextEditable: true,
  },
  {
    label: "카테고리",
    name: "categoryIds",
    style: { minWidth: 300 },
    isMultipleSelectable: true,
  },
  {
    label: "좋아요",
    name: "likedSum",
    style: { minWidth: 100 },
    isAscending: false,
    isTextEditable: false,
  },
  {
    label: "발송 주기(old)",
    name: "contentReleaseSchedule",
    style: { minWidth: 180 },
    isAscending: false,
    isTextEditable: true,
  },
  {
    label: "발송 주기(매일,매주,매일)",
    name: "contentReleaseCycle",
    style: { minWidth: 180 },
    isAscending: false,
    isTextEditable: true,
  },
  {
    label: "발송 주기(요일)",
    name: "contentReleaseDays",
    style: { minWidth: 180 },
    isAscending: false,
    isTextEditable: true,
  },
  {
    label: "태그",
    name: "tags",
    style: { minWidth: 200 },
    isAscending: false,
    isTextEditable: true,
  },
  {
    label: "구독 확인 절차",
    name: "confirmationEmail",
    style: { minWidth: 200 },
    isAscending: false,
    isTextEditable: true,
  },
  {
    label: "이슈",
    name: "issue",
    style: { minWidth: 200 },
    isAscending: false,
    isTextEditable: true,
  },
  {
    label: "최초 등록 일시",
    name: "createdAt",
    isAscending: false,
    style: { minWidth: 120 },
  },
  {
    label: "크리에이터",
    name: "creator",
    isAscending: false,
    style: { minWidth: 120 },
  },
  {
    label: "최근 수정 일시",
    name: "updatedAt",
    isAscending: false,
    style: { minWidth: 120 },
  },
  // {
  //   label: 'ID',
  //   name: '_id',
  //   style: { maxWidth: 200 },
  //   isAscending: false,
  // },
  {
    label: "프리미엄 유무",
    name: "isPremium",
    isAscending: false,
    style: { minWidth: 120 },
  },
];

let newsletterList: any = [];
let categoryList: any = [];
let categoryFullList: any = [];
export const NewsletterList = () => {
  const [items, setItems] = useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();

  // const [locale, setLocale] = React.useState<Locale | null>(null)
  const [name, setName] = React.useState('images');
  const [url, setUrl] = React.useState('https://mqnddbigxf.execute-api.ap-northeast-2.amazonaws.com/v1/upload');
  const [method, setMethod] = React.useState('POST');

  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(10);
  const [metagroupCount, setMetagroupCount] = React.useState(0);
  const [visibledMetagroupCount, setVisibledMetagroupCount] = React.useState(0);

  let currentPage = 1;
  let limit = '100';
  let search = '';
  let sortField  = '';
  let sortDesc  = '';

  // const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  const handlePageChange = (event: any, value: number) => {
    // setPage(value);
    console.log('sortField', sortField);
    changePage(value);

  };

  // const [page, setPage] = React.useState('1');
  // const [limit, setLimit] = React.useState('100');


  const inputRef = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  // const inputEl = useRef();
  //

  let selectedId = '';

  const imageUpload = (id: string): void => {
    if (!divRef.current) {
      return;
    }

    selectedId = id;

    const element = document.getElementsByClassName('file-uploader-container')[0].children[1];
    if (element instanceof HTMLElement) {
      element.click();
    }
  }

  const beforeRequest= (request: XMLHttpRequest, formData: FormData) => {
    console.log('access token : ', accessToken);
    request.setRequestHeader('Authorization', `Bearer ${accessToken}` );
    // request.setRequestHeader('Authorization', 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjBwdmRfVkxqSW9kb21XaWlXOWZmYiJ9.eyJodHRwczovL2FkbWluLmhleWJ1bm55LmlvL3JvbGVzIjpbImFkbWluIl0sImlzcyI6Imh0dHBzOi8vYWNjb3VudHMuaGV5YnVubnkuaW8vIiwic3ViIjoiYXV0aDB8NjA3NGU0MTdiMDBmYTYwMDY5OGE3ZjA1IiwiYXVkIjpbImh0dHBzOi8vYXBpMy5oZXlidW5ueS5pbyIsImh0dHBzOi8vdHdpZ2Zhcm0uZXUuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTYyODc1MDUxNywiZXhwIjoxNjI4ODM2OTE3LCJhenAiOiJIQ1JGakd6QVlUZ0JjSlpFRThKYzRqZ29RUHlHd2tCaCIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwifQ.BBLkOgQ1aoIPTSiALj-6D5qHwzK3TdKnJfhhejTOP-rGPT7FChMS4TFvwWS3-P961dAckAcIoGwNraq2ncyV8vg_QHJ7jsPb9zyTVCot3DErLhWsJe1kRHwNtzI-ICNPqkp4n_pH7RTwpVlX6ah-_gPXKhMQdDuE9vK3ZOrstNM0eLcMkcMc9Mi8ANkQSSpAsSmCg8iJWQMjeEEBE2VwDAJUOHc8u5gcCHolldmkJbAY7mf_sRBwknU3gGjRrrG-z_zG-JobBJwAgJjUmUp63WBSm_9waQHp7Pu4L7jC47Lx1gLltdyQ8L3QWSKfBC8bPnU0QmQ8i0dzOfMUSl6bJg');
    formData.append("abc", '123');
    // return Promise.resolve(formData);
  }

  const fileUploaded = (response: any) => {
    const jsonObj = JSON.parse(response);

    handleInputSubmit(selectedId, 'thumbnails', jsonObj.large);

    const element = document.getElementsByClassName(`thumbnail-${selectedId}`)[0];
    if(element instanceof HTMLImageElement){
      console.log('String');
      setTimeout(()=> {

        const textarea = document.getElementById(selectedId);
        if(textarea instanceof HTMLTextAreaElement){
          textarea.value = jsonObj.large;
        }

        element.src = jsonObj.large;
        element.width = 100;

      }, 4000);
    }

  }
  const fileGot = (file: File | Blob) => {
    
  };

  const constructList = async (isReload: boolean = false) => {
    if (isReload || newsletterList.length === 0) {
      // setItems([]);
      setLoading(true);
      setAccessToken(await getAccessTokenSilently());
      const result = await getNewsletterList({ params: { page: currentPage, limit: limit, search: search, sort: sortField, sortDesc } });

      // const newsletterCount = result.metadataTotal-(parseInt(page, 10)-1)*parseInt(limit, 10);
      let newsletterCount = result.metadataTotal - (result.pagination.page - 1) * result.pagination.limit;
      console.log("newsletterCount", newsletterCount);

      let calcPage: number = Math.floor(result.total / result.pagination.limit);
      // calcPage = parseInt(calcPage);
      if (result.total % result.pagination.limit > 0) {
        // console.log('dddddd');
        calcPage++;
      }
      setTotalPage(calcPage);
      setMetagroupCount(result.metagroupCount || 0);
      setVisibledMetagroupCount(result.visibledMetagroupCount || 0);

      newsletterList = result.metadataList.map((newsletter: any, index: number) => {
        return { ...newsletter, orderNo: newsletterCount - index };
      });
      categoryFullList = result.categoryList;
      categoryList = result.categoryList.filter((elem: any) => !elem.hasOwnProperty("isPromotion") || elem.isPromotion !== true);
    }
    setItems(
      newsletterList.map((item: any, index: number) => {
        return {
          ...item,
          createdAt: moment(item.createdAt).format("YYYY.MM.DD HH:mm:ss"),
          updatedAt: moment(item.updatedAt).format("YYYY.MM.DD HH:mm:ss"),
          visibledAt: item.visibledAt ? moment(item.visibledAt).format("YYYY.MM.DD HH:mm:ss") : "-",
          categoryIds:
            item.categoryIds?.map((item: any) => {
              const index = categoryFullList.findIndex((elem: any) => elem._id === item);
              if (index === -1) {
                console.log("index: ", index, "_id: ", item);
              }
              return {
                _id: item,
                categoryName: categoryFullList[index].categoryName,
              };
              // return item._id;
            }) || [],
        };
      })
    );

    setLoading(false);
  };

  useEffect(() => {
    constructList();
  }, []);

  const submit = (e: any) => {
    console.log(e.keyCode);

    if (e.keyCode === 13) {
      e.preventDefault();
      changePage(1);
      // e.stopImmediatePropagation();
      //Do your stuff...
    }

    // if(e.keyCode===13){
    //   changePage(1);
    //   return false;
    // }
  };

  const changePage = (pg: number = 1) => {
    // const pageinput = document.getElementById('input_page_number');
    // if(pageinput instanceof HTMLInputElement){
    currentPage = pg;
    //   // setPage(parseInt(pageinput.value));
    // }
    setPage(pg);

    const limitinput = document.getElementById("input_page_limit");
    if (limitinput instanceof HTMLInputElement) {
      limit = limitinput.value;
    }

    const searchinput = document.getElementById("input_search");
    if (searchinput instanceof HTMLInputElement) {
      search = searchinput.value;
    }

    const sortFieldInput = document.getElementById("sort_field");
    if (sortFieldInput instanceof HTMLInputElement) {
      sortField = sortFieldInput.value;
    }

    const sortDescInput = document.getElementById("sort_desc");
    if (sortDescInput instanceof HTMLInputElement) {
      sortDesc = sortDescInput.value;
    }

    console.log("sortField", sortField);

    setTimeout(() => {
      constructList(true);
    }, 100);
  };

  const sort = (col: any) => {
    const index = columnInfo.findIndex((e) => e.name === col.name);
    columnInfo[index].isAscending = !columnInfo[index].isAscending;

    sortField = columnInfo[index].name;
    if (sortField === "orderNo") sortField = "createdAt";
    sortDesc = columnInfo[index].isAscending ? "1" : "-1";

    const sortFieldInput = document.getElementById("sort_field");
    if (sortFieldInput instanceof HTMLInputElement) {
      sortFieldInput.value = sortField;
    }

    const sortDescInput = document.getElementById("sort_desc");
    if (sortDescInput instanceof HTMLInputElement) {
      sortDescInput.value = sortDesc;
    }

    currentPage = 1;

    setPage(1);
    handlePageChange(null, 1);

    // alert(sortField);

    // const sortedItems = [...newsletterList].sort((a: any, b: any) => {
    //   if (typeof a[col.name] === 'number' || typeof a[col.name] === 'boolean') {
    //     return columnInfo[index].isAscending ? a[col.name] - b[col.name] : b[col.name] - a[col.name];
    //   }
    //
    //   if (!a[col.name]) {
    //     a[col.name] = '';
    //   }
    //   if (!b[col.name]) {
    //     b[col.name] = '';
    //   }
    //
    //   return columnInfo[index].isAscending ? a[col.name].localeCompare(b[col.name]) : b[col.name].localeCompare(a[col.name]);
    // });
    // newsletterList = sortedItems;
    // setItems(sortedItems);
    // changePage();

    // constructList(true);
  };

  const handleInputSubmit = async (id: string, name: string, value: any) => {
    const index = newsletterList.findIndex((newsletter: any) => newsletter._id === id);
    if (name === "tags" || name === "metaIds") {
      value = value.split("\n");
      value = value.map((item: any) => item.trim());
    }

    newsletterList[index][name] = value;
    console.log(id, name, value);
    constructList();
    await putNewsletter(id, { [name]: value });
  };

  const handleCategoryChange = async (id: string, name: string, value: any) => {
    console.log("selectedList: ", value);
    const index = newsletterList.findIndex((newsletter: any) => newsletter._id === id);
    const valueToSave = value.map((category: any) => category._id);
    newsletterList[index][name] = valueToSave;
    constructList();
    await putNewsletter(id, { [name]: valueToSave });
  };

  // if (newsletterList.length === 0) {
  //   return <h1>Loading...</h1>;
  // }
  const ADMIN_PASSWORD = "heybunny!";
  const [sourceId, setSourceId] = React.useState("");
  const [sourceTitle, setSourceTitle] = React.useState("");
  const [sourceMetaIds, setSourceMetaIds] = React.useState<Array<string>>([]);
  const [targetId, setTargetId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [targetMetaGroup, setTargetMetaGroup] = React.useState({ _id: "", title: "", metaIds: [] });
  const [open, setOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  // const [savingTitle, setSavingTitle] = React.useState('통합하기');
  const [resultView, setResultview] = React.useState(false);
  const [resultMessage, setResultMessage] = React.useState("");
  const passwordInputRef = React.useRef<HTMLInputElement>(null);

  const [ignoreOpen, setIgnoreOpen] = React.useState(false);
  const [ignoreValue, setIgnoreValue] = React.useState(false);

  const AddComma = (num: number) => {
    const regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ",");
  };

  const handleOpen = (id: string, title: string, metaIds: string[]) => {
    setSourceId(id);
    setSourceTitle(title);
    setSourceMetaIds(metaIds);

    setTargetId("");
    setTargetMetaGroup({ _id: "", title: "", metaIds: [] });
    setPassword("");
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleChange = (e: any) => {
    console.log("handleChange : ", e.target.value);
    setTargetId(e.target.value);
  };

  const handleIgnoreOpen = (id: string, title: string, ignoreValue: boolean) => {
    setSourceId(id);
    setSourceTitle(title);
    setIgnoreValue(ignoreValue);

    // setTargetId('');
    // setTargetMetaGroup({_id: '', title: '', metaIds: []});
    setPassword("");
    setIgnoreOpen(true);
  };

  const handleIgnoreClose = () => setIgnoreOpen(false);

  const handleIgnoreChange = (value: any) => {
    // console.log('handleIgnoreChange : ' , value);
    setIgnoreValue(!ignoreValue);
  };

  const handlePasswordChange = (e: any) => {
    console.log("handlePasswordChange : ", e.target.value);
    setPassword(e.target.value);
  };

  const getTargetMetaGroup = async () => {
    if (targetId) {
      const response = await getNewsletter(targetId);
      console.log("response ===> ", response);
      if (response && response._id) {
        setTargetMetaGroup(response);
        // console.log(response.data);
      } else {
        setTargetMetaGroup({ _id: "", title: "", metaIds: [] });
      }
    }
  };

  const mergeMetaGroup = async () => {
    if (isSaving) return;
    if (sourceId && targetId) {
      setIsSaving(true);
      const response = await putNewsletter(sourceId, { parentMetaGroupId: targetId });
      console.log("response ===> ", response);
      setIsSaving(false);
      setPassword("");

      let pwInput = document.getElementById("merge-password");
      if (pwInput instanceof HTMLInputElement) {
        pwInput.value = "";
      }

      if (response && response.message === "ok") {
        setResultMessage("뉴스레터가 성공적으로 통합 되었습니다");
        setTimeout(() => {
          // constructList(true);
          changePage(page);
        }, 500);
      } else {
        setResultMessage("뉴스레터 통합이 실패 되었습니다.");
      }
      setResultview(true);
      setTimeout(() => {
        setResultview(false);
      }, 3000);
      // setPassword('');
    }
  };

  const ignoreMetaGroup = async () => {
    if (isSaving) return;
    if (sourceId) {
      setIsSaving(true);
      const response = await putNewsletter(sourceId, { ignore: ignoreValue });
      console.log("response ===> ", response);
      setIsSaving(false);
      setPassword("");

      console.log("ignoreValue", ignoreValue);

      let pwInput = document.getElementById("merge-password");
      if (pwInput instanceof HTMLInputElement) {
        pwInput.value = "";
      }

      // if(response && response.message === 'ok'){
      setResultMessage("발행처 뉴스레터로 성공적으로 처리 되었습니다");
      setTimeout(() => {
        // constructList(true);
        changePage(page);
      }, 500);
      // }else{
      //   setResultMessage('발행처 뉴스레터 처리가 실패 되었습니다.');
      // }
      setResultview(true);
      setTimeout(() => {
        setResultview(false);
      }, 3000);
      // setPassword('');
    }
  };

  return (
    <div>
      <h1>뉴스레터 관리 {metagroupCount ? `[${AddComma(visibledMetagroupCount)}/${AddComma(metagroupCount)}]` : ""}</h1>
      {/*페이지번호 : <input id='input_page_number' type='text' defaultValue={page} /><br/>*/}
      페이지당 개수 : <input id="input_page_limit" type="text" defaultValue={limit} />
      <br />
      뉴스레터 이름 : <input id="input_search" type="text" defaultValue={search} onKeyUp={(e) => submit(e)} />
      <input id="sort_field" type="hidden" defaultValue="" />
      <input id="sort_desc" type="hidden" defaultValue="" />
      <button onClick={(e) => changePage(1)} style={{ marginLeft: "10px" }}>
        {" "}
        적용
      </button>
      <div style={{ padding: "8px" }}></div>
      <Pagination count={totalPage} page={page} onChange={handlePageChange} variant="outlined" color="secondary" />
      <div style={{ display: "none" }}>
        <h3>upload the file to server</h3>
        name:
        <input type="text" defaultValue={name} onChange={(e) => setName(e.currentTarget.value)} />
        url:
        <input type="text" defaultValue={url} onChange={(e) => setUrl(e.currentTarget.value)} />
        method:
        <input type="text" defaultValue={method} onChange={(e) => setMethod(e.currentTarget.value)} />
      </div>
      {/*<input type='text' id='text-to-copy' value = ''/>*/}
      {/*<img className="image1" src = ""/>*/}
      <div className="uploader-wrapper" ref={divRef} style={{ display: "none" }}>
        <FileUploader
          fileUploaded={fileUploaded}
          fileGot={fileGot}
          accept="image/*"
          multiple={false}
          // locale={locale}
          name={name.replace(/ /g, "")}
          url={url}
          method={method}
          beforeRequest={beforeRequest}
        ></FileUploader>
      </div>
      {/*<div><label><input type="file" multiple={false}  accept="image/*" /></label></div>*/}
      {/*<div><button id="12345" onClick={(event: any, id: string) => {imageUpload(event, '1')}}> 이미지 업로드</button></div>*/}
      <div>
        {/*<Button onClick={() => handleOpen('1', 'test', ['22222'])}>설정</Button>*/}
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Item>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    [뉴스레터 통합]
                  </Typography>
                </Item>
              </Grid>
              <Grid item xs={5}>
                <Typography id="modal-modal-description1" sx={{ mt: 2 }} variant="body1">
                  <div style={{ marginTop: "62px" }}></div>
                  뉴스레터 ID : {sourceId}
                  <br />
                  뉴스레터 Title :{sourceTitle}
                  <br />
                  <TextField
                    style={{ fontSize: "16px", width: "330px", marginTop: "10px" }}
                    id="outlined-multiline-static"
                    label="발송주소"
                    multiline
                    rows="10"
                    defaultValue={sourceMetaIds.join("\n")}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                  />
                  {/*발송주소 :{JSON.stringify(sourceMetaIds || [])}<br/>*/}
                  {/*발송주소 :<br/>*/}
                  {/*{*/}
                  {/*  sourceMetaIds.map(item => {*/}
                  {/*    return <div style={{fontSize: '0.875rem'}}>{item}</div>;*/}
                  {/*  })*/}
                  {/*}*/}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <div style={{ marginTop: "80px" }}>
                  <Item>
                    <DoubleArrowIcon sx={{ fontSize: 40 }} />
                  </Item>
                </div>
              </Grid>
              <Grid item xs={5}>
                <div>
                  <TextField id="outlined-basic" label="대표 ID" variant="outlined" onChange={handleChange} />
                  <Button variant="contained" size="large" onClick={getTargetMetaGroup}>
                    조회
                  </Button>
                  <Typography id="modal-modal-description2" sx={{ mt: 2 }}>
                    {targetMetaGroup._id && (
                      <>
                        {/*[대표 뉴스레터] <br/>*/}
                        뉴스레터 ID : {targetMetaGroup._id}
                        <br />
                        뉴스레터 Title :{targetMetaGroup.title}
                        <br />
                        {/*발송주소 :{JSON.stringify(targetMetaGroup.metaIds || [])}<br/>*/}
                        {/*발송주소 :<br/>*/}
                        <TextField
                          style={{ fontSize: "16px", width: "330px", marginTop: "10px" }}
                          id="outlined-multiline-static"
                          label="발송주소"
                          multiline
                          rows="10"
                          defaultValue={
                            targetMetaGroup.metaIds.join("\n")
                            // targetMetaGroup.metaIds.map(item => {
                            //   return <div style={{fontSize: '0.875rem'}}>{item}</div>;
                            // })
                          }
                          variant="outlined"
                          inputProps={{ readOnly: true }}
                        />
                        {/*{*/}
                        {/*  targetMetaGroup.metaIds.map(item => {*/}
                        {/*    return <div style={{fontSize: '0.875rem'}}>{item}</div>;*/}
                        {/*  })*/}
                        {/*}*/}
                      </>
                    )}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <div>
                    {targetMetaGroup._id && sourceId !== targetMetaGroup._id && (
                      <>
                        <TextField id="merge-password" label="패스워드" variant="standard" onChange={handlePasswordChange} ref={passwordInputRef} />
                        <Button variant="contained" onClick={mergeMetaGroup} disabled={isSaving || password !== ADMIN_PASSWORD}>
                          {!isSaving ? "통합하기" : "통합중.."}
                        </Button>
                      </>
                    )}
                    <span style={{ marginLeft: "30px" }}>
                      <Button variant="outlined" onClick={handleClose}>
                        닫기(취소)
                      </Button>
                    </span>
                  </div>
                  {resultView && (
                    <div>
                      <Alert severity="info" color="info">
                        {resultMessage}
                        {/*뉴스레터가 성공적으로 통합되었습니다.*/}
                      </Alert>
                    </div>
                  )}
                </Item>
              </Grid>
            </Grid>

            {/*<Typography id="modal-modal-title" variant="h6" component="h2">*/}
            {/*  [뉴스레터 메타 통합]*/}
            {/*</Typography>*/}
            {/*<Typography id="modal-modal-description1" sx={{ mt: 2 }}>*/}
            {/*  뉴스레터 ID : {sourceId}<br/>*/}
            {/*  뉴스레터 Title :{sourceTitle}<br/>*/}
            {/*  발송주소 :{JSON.stringify(sourceMetaIds || [])}<br/>*/}
            {/*</Typography>*/}
            {/*-------*/}
            {/*<br/>*/}
            {/*<br/>*/}
            {/*<TextField id="outlined-basic" label="상위 ID" variant="outlined" onChange={handleChange} />*/}
            {/*<Button variant="contained" onClick={getTargetMetaGroup}>조회</Button>*/}
            {/*<Typography id="modal-modal-description2" sx={{ mt: 2 }}>*/}
            {/*  {targetMetaGroup._id && (<>*/}
            {/*  [상위 뉴스레터 정보] <br/>*/}
            {/*  뉴스레터 ID : {targetMetaGroup._id}<br/>*/}
            {/*  뉴스레터 Title :{targetMetaGroup.title}<br/>*/}
            {/*  발송주소 :{JSON.stringify(targetMetaGroup.metaIds || [])}<br/>*/}
            {/*    </>)}*/}

            {/*</Typography>*/}
          </Box>
        </Modal>
      </div>
      <div>
        <Modal open={ignoreOpen} onClose={handleIgnoreClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={ignoreStyle}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Item>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    [구독 제외 뉴스레터 설정]
                    <br />
                    <span style={{ fontSize: "14px" }}>{sourceTitle}</span>
                  </Typography>
                </Item>
              </Grid>

              <Grid item xs={12}>
                {/*<Item>*/}
                <Alert severity="warning">
                  <AlertTitle>주의</AlertTitle>
                  ON 으로 설정하는 경우,
                  <br />- 해당 뉴스레터는 모든 사용자의 <strong>뉴스레터 구독 목록에서 제외</strong>됩니다.
                  <br />- 해당 뉴스레터의 아티클이 수신되더라도 사용자의 뉴스레터 구독 목록에 포함되지 않습니다.
                </Alert>
                {/*</Item>*/}
              </Grid>

              <Grid item xs={5}>
                <div />
              </Grid>
              <Grid item xs={2}>
                <Typography id="modal-modal-description1" sx={{ mt: 2 }} variant="body1">
                  <Switch checked={ignoreValue} onChange={handleIgnoreChange} inputProps={{ "aria-label": "controlled" }} />
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <div />
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <div>
                    <TextField id="merge-password" label="패스워드" variant="standard" onChange={handlePasswordChange} ref={passwordInputRef} />
                    <Button sx={{ marginTop: "12px" }} variant="contained" onClick={ignoreMetaGroup} disabled={isSaving || password !== ADMIN_PASSWORD}>
                      {!isSaving ? "적용하기" : "적용중.."}
                    </Button>
                    <span style={{ marginLeft: "30px" }}>
                      <Button sx={{ marginTop: "12px" }} variant="outlined" onClick={handleIgnoreClose}>
                        닫기(취소)
                      </Button>
                    </span>
                  </div>
                  {resultView && (
                    <div>
                      <Alert severity="info" color="info">
                        {resultMessage}
                        {/*뉴스레터가 성공적으로 통합되었습니다.*/}
                      </Alert>
                    </div>
                  )}
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
      <MainList
        onSortToggle={sort}
        onInputSubmit={handleInputSubmit}
        columnInfo={columnInfo}
        listData={items}
        multipleSelectList={categoryList}
        onMultipleSelectCheckboxChange={handleCategoryChange}
        buttons={[]}
        imageUpload={imageUpload}
        handleOpen={handleOpen}
        handleIgnoreOpen={handleIgnoreOpen}
      />
      <Box sx={{ "& > :not(style)": { m: 1 } }} style={{ position: "fixed", top: "70px", left: "950px" }}>
        <Fab color="primary" aria-label="refresh">
          <RefreshIcon onClick={(e) => changePage(page)} />
        </Fab>
        {loading && (
          <CircularProgress
            size={68}
            sx={{
              color: green[500],
              position: "absolute",
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box>
    </div>
  );
};
