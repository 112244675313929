import { useAuth0 } from '@auth0/auth0-react';
import { FormControlLabel, Switch, TextField } from '@material-ui/core';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import qs from 'qs';
import { RouteComponentProps } from 'react-router-dom';
import { FileUploader } from "file-uploader-react-component";
import {getNewsletter, getBannerList, putNewsletter, putBanner, putNewsletterBanner} from '../utils/api';
import { accessToken, setAccessToken } from '../utils/auth';
import { MainList } from './MainList';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const columnInfo = [
    {
        label: '뉴스레터 ID',
        name: 'metaGroupId',
        isAscending: false,
        isTextEditable: true,
        style: { minWidth: 200 },
    },
    {
        label: '뉴스레터 명',
        name: 'newsletterTitle',
        isAscending: false,
        isTextEditable: false,
        style: { minWidth: 150 },
    },
    {
        label: '노출순서',
        name: 'order',
        isAscending: true,
        isTextEditable: true,
        style: { minWidth: 110 },
    },

    {
        label: '서브타이틀',
        name: 'title',
        isAscending: false,
        isTextEditable: true,
        style: { minWidth: 220 },
    },

    {
        label: '배너 내용',
        name: 'content',
        style: { minWidth: 200 },
        isTextEditable: true,
    },

    {
        label: '노출여부',
        name: 'visible',
        isAscending: false,
    },
    {
        label: '노출기간',
        name: 'visiblePeriod',
        isAscending: false,
        style: { minWidth: 250 },
    },
    {
        label: '배너 이미지(슬림)',
        name: 'slimImageUrl',
        style: { minWidth: 150, maxWidth: 250 },
        isTextEditable: true,
    },
    {
        label: '배너 타입',
        name: 'type',
        style: { minWidth: 100 },
        isSingleSelectable: true,
    },
    {
        label: '연관 컨텐츠 ID',
        name: 'typeContentId',
        style: { minWidth: 200 },
        isTextEditable: true,
    },
    {
        label: '필터(사용자유형)',
        name: 'filterUserType',
        style: { minWidth: 220 },
        isTextEditable: true,
    },
    {
        label: '최초 등록 일시',
        name: 'createdAt',
        isAscending: false,
        style: { minWidth: 120 },
    },
    {
        label: '최근 수정 일시',
        name: 'updatedAt',
        isAscending: false,
        style: { minWidth: 120 },
    },

    {
        label: 'ID',
        name: '_id',
        style: { maxWidth: 150 },
        isAscending: false,
    },


];

let bannerList: any = [];
let metadataList: any = [];
export const NewsletterBannerList = () => {
    const [items, setItems] = useState<any>([]);
    const { getAccessTokenSilently } = useAuth0();

    // const [locale, setLocale] = React.useState<Locale | null>(null)
    const [name, setName] = React.useState('banners');
    const [url, setUrl] = React.useState('https://mqnddbigxf.execute-api.ap-northeast-2.amazonaws.com/v1/upload');
    const [method, setMethod] = React.useState('POST');

    // const [page, setPage] = React.useState('1');
    // const [limit, setLimit] = React.useState('100');

    let page = '1';
    let limit = '1000';
    let search = '';

    const inputRef = useRef<HTMLInputElement>(null);
    const divRef = useRef<HTMLDivElement>(null);

    // const inputEl = useRef();
    //

    let selectedId = '';
    let selectedType = '';

    const bannerImageUpload = (id: string, type: string): void => {
        if (!divRef.current) {
            return;
        }

        selectedId = id;
        selectedType = type;

        const element = document.getElementsByClassName('file-uploader-container')[0].children[1];
        if (element instanceof HTMLElement) {
            element.click();
        }
    }

    const beforeRequest= (request: XMLHttpRequest, formData: FormData) => {
        console.log('access token : ', accessToken);
        request.setRequestHeader('Authorization', `Bearer ${accessToken}` );
        // request.setRequestHeader('Authorization', 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjBwdmRfVkxqSW9kb21XaWlXOWZmYiJ9.eyJodHRwczovL2FkbWluLmhleWJ1bm55LmlvL3JvbGVzIjpbImFkbWluIl0sImlzcyI6Imh0dHBzOi8vYWNjb3VudHMuaGV5YnVubnkuaW8vIiwic3ViIjoiYXV0aDB8NjA3NGU0MTdiMDBmYTYwMDY5OGE3ZjA1IiwiYXVkIjpbImh0dHBzOi8vYXBpMy5oZXlidW5ueS5pbyIsImh0dHBzOi8vdHdpZ2Zhcm0uZXUuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTYyODc1MDUxNywiZXhwIjoxNjI4ODM2OTE3LCJhenAiOiJIQ1JGakd6QVlUZ0JjSlpFRThKYzRqZ29RUHlHd2tCaCIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwifQ.BBLkOgQ1aoIPTSiALj-6D5qHwzK3TdKnJfhhejTOP-rGPT7FChMS4TFvwWS3-P961dAckAcIoGwNraq2ncyV8vg_QHJ7jsPb9zyTVCot3DErLhWsJe1kRHwNtzI-ICNPqkp4n_pH7RTwpVlX6ah-_gPXKhMQdDuE9vK3ZOrstNM0eLcMkcMc9Mi8ANkQSSpAsSmCg8iJWQMjeEEBE2VwDAJUOHc8u5gcCHolldmkJbAY7mf_sRBwknU3gGjRrrG-z_zG-JobBJwAgJjUmUp63WBSm_9waQHp7Pu4L7jC47Lx1gLltdyQ8L3QWSKfBC8bPnU0QmQ8i0dzOfMUSl6bJg');
        formData.append("type", 'banner');
        // return Promise.resolve(formData);
    }

    const fileUploaded = (response: any) => {
        const jsonObj = JSON.parse(response);

        handleInputSubmit(selectedId, selectedType, jsonObj.original);

        // const element = document.getElementsByClassName(`thumbnail-${selectedId}`)[0];
        // if(element instanceof HTMLImageElement){
        //     console.log('String');
        //     setTimeout(()=> {
        //
        //         const textarea = document.getElementById(selectedId);
        //         if(textarea instanceof HTMLTextAreaElement){
        //             textarea.value = jsonObj.large;
        //         }
        //
        //         element.src = jsonObj.large;
        //         element.width = 100;
        //
        //     }, 4000);
        // }

    }
    const fileGot = (file: File | Blob) => {

    }

    let visible = '';

    const constructList = async (isReload: boolean = false) => {
        if (isReload || bannerList.length === 0) {
            setAccessToken(await getAccessTokenSilently());
            const result = await getBannerList({params: {bannerType: 'newsletter', page: page, limit: limit, search: search, ...(visible !== '' ? {visible} : {})}});

            const bannerCount = result.length;
            // console.log("newsletterCount", newsletterCount);

            bannerList = result.bannerList.map((banner: any, index: number) => {
                return { ...banner, orderNo: bannerCount - index };
            });
            metadataList = result.metadataList;

        }

        setItems(
            bannerList.map((item: any, index: number) => {
                return {
                    ...item,
                    createdAt: moment(item.createdAt).format('YYYY.MM.DD HH:mm:ss'),
                    updatedAt: moment(item.updatedAt).format('YYYY.MM.DD HH:mm:ss'),
                };
            })
        );
    };

    useEffect(() => {
        constructList();
    }, []);


    // const changePage = () => {
    //
    //     const pageinput = document.getElementById('input_page_number');
    //     if(pageinput instanceof HTMLInputElement){
    //         page = pageinput.value;
    //     }
    //
    //
    //     const limitinput = document.getElementById('input_page_limit');
    //     if(limitinput instanceof HTMLInputElement){
    //         limit = limitinput.value;
    //     }
    //
    //     const searchinput = document.getElementById('input_search');
    //     if(searchinput instanceof HTMLInputElement){
    //         search = searchinput.value;
    //     }
    //
    //     setTimeout(()=> {
    //         constructList(true);
    //     }, 500);
    //
    // };

    const sort = (col: any) => {
        const index = columnInfo.findIndex((e) => e.name === col.name);
        columnInfo[index].isAscending = !columnInfo[index].isAscending;
        const sortedItems = [...bannerList].sort((a: any, b: any) => {
            if (typeof a[col.name] === 'number' || typeof a[col.name] === 'boolean') {
                return columnInfo[index].isAscending ? a[col.name] - b[col.name] : b[col.name] - a[col.name];
            }

            if (!a[col.name]) {
                a[col.name] = '';
            }
            if (!b[col.name]) {
                b[col.name] = '';
            }

            return columnInfo[index].isAscending ? a[col.name].localeCompare(b[col.name]) : b[col.name].localeCompare(a[col.name]);
        });
        bannerList = sortedItems;
        // setItems(sortedItems);
        constructList();
    };

    const handleInputSubmit = async (id: string, name: string, value: any) => {
        const index = bannerList.findIndex((banner: any) => banner._id === id);

        // if((name === 'visibleStartDate' || name === 'visibleEndDate') && value) {
        //     try{
        //         value = new Date(value);
        //     }catch (e) {
        //
        //     }
        // }
        console.log(id, name, value, index);
        bannerList[index][name] = value;
        console.log(id, name, value);
        constructList();
        await putNewsletterBanner(id, { [name]: value });
        console.log(JSON.stringify({  bannerType: 'newsletter', [name]: value }));

        // setTimeout(()=> {
        if (name === 'slimImageUrl' || name === 'homeImageUrl') {
            const element = document.getElementsByClassName(`${name}-${id}`)[0];
            if (element instanceof HTMLImageElement) {
                console.log('String');
                element.src = value;
            }
        }
        // }, 1000);
    };

    const handleNewsletterChange = async (id: string, name: string, value: any) => {
        console.log('selectedList: ', value);
        const index = bannerList.findIndex((newsletter: any) => newsletter._id === id);
        const valueToSave = value.map((category: any) => category._id);
        bannerList[index][name] = valueToSave;
        constructList();
        await putNewsletter(id, { [name]: valueToSave });
    };

    // const [targetId, setTargetId] = React.useState('');
    // const [password, setPassword] = React.useState('');
    // const [targetMetaGroup, setTargetMetaGroup] = React.useState({_id: '', title: '', metaIds: []});
    // const [open, setOpen] = React.useState(false);
    // const [isSaving, setIsSaving] = React.useState(false);

    type Option = {
        label: string;
        value: string;
    };

    const options: Option[] = [
        { label: "전체", value: "" },
        { label: "노출", value: "true" },
        { label: "비노출", value: "false" },
    ];

    const [selectedOption, setSelectedOption] = useState<Option>(options[0]);

    const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const optionValue = event.target.value;
        const option = options.find((opt) => opt.value === optionValue);
        if (option) {
            visible = optionValue;
            setSelectedOption(option);
            // constructList(true)
            setTimeout(()=> {
                constructList(true);
            }, 100);
        }
    };

    return (
        <div>
            <h1>뉴스레터 배너 관리</h1>

            <div>
                <label htmlFor="select-option">노출여부:</label>
                <select id="select-option" onChange={handleOptionChange} value={selectedOption.value}>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>

            <div className="uploader-wrapper" ref={divRef} style={{display: 'none'}}>
                <FileUploader fileUploaded={fileUploaded}
                              fileGot={fileGot}
                              accept='image/*'
                              multiple={false}
                    // locale={locale}
                              name={name}
                              url={url}
                              method={method}
                              beforeRequest= {beforeRequest}
                >
                </FileUploader>
            </div>

            <MainList
                onSortToggle={sort}
                onInputSubmit={handleInputSubmit}
                columnInfo={columnInfo}
                listData={items}
                multipleSelectList={metadataList}
                onMultipleSelectCheckboxChange={handleNewsletterChange}
                buttons={[]}
                bannerImageUpload={bannerImageUpload}
                // handleOpen={handleOpen}
            />
        </div>
    );
};
