import {
  createStyles,
  FormControlLabel,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import React, { useEffect, useState } from 'react';
import MultipleSelector from './MultipleSelector';
import {getNewsletter} from "../utils/api";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField2 from '@mui/material/TextField';

interface Pagination {
  has_next?: boolean;
  has_prev?: boolean;
  page_no?: number;
  page_size: number;
  total_pages?: number;
  total_rows?: number;
}

export interface ListParam {
  page_size?: number;
  page_no?: number;
  start_date?: number | null;
  end_date?: number | null;
  keyword?: string;
}

interface MainListProps {
  listData: any;
  columnInfo: Array<any>;
  selectedItems?: any;
  sortable?: boolean;
  tabs?: boolean;
  buttons?: Array<any>;
  pagination?: Pagination;
  multipleSelectList?: any;
  onChangeDate?: (date: number | null) => void;
  noPagination?: boolean;
  onSortToggle?: (col: any) => void;
  onInputSubmit?: (id: string, name: string, value: any) => void;
  onMultipleSelectCheckboxChange?: (id: string, name: string, value: any) => void;
  imageUpload?: (id: string) => void;
  bannerImageUpload?: (id: string, type: string) => void;
  changeParentMetaGroup?: (id: string) => void;
  handleOpen?: (id: string, title: string, metaIds: string[]) => void;
  handleIgnoreOpen?: (id: string, title: string, value: boolean) => void;
  handleConfirmEmailOpen?: (id: string, title: string, value: boolean) => void;
  curationCategoryList?: any;
  stickerCategoryList?: any;
}

export const MainList = (props: MainListProps) => {
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(0);
  const [selectedCell, setSelectedCell] = useState<any>(null);


  let items = props.listData || [];
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: '#fafafa',
        color: '#212121',
        fontWeight: 500,
      },
      body: {
        fontSize: 14,
        fontWeight: 'normal',
      },
    })
  )(TableCell);

  const useListStyles = makeStyles((theme: Theme) => ({
    tableContainer: {
      paddingTop: 0,
      overflowX: 'initial',
      overflowY: 'scroll',
      maxHeight: '80vh',
    },
    table: {
      // tableLayout: 'auto',
    },

    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 0,
      height: 50,
    },

    headerLabel: {
      display: 'flex',
      alignItems: 'center',
      '&:hover:not(.Mui-disabled)': {
        cursor: 'pointer',
      },
    },

    topRightSection: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        marginLeft: theme.spacing(2),
      },
    },
  }));

  const handleToggleSortClick = (col: any, index: number) => {
    if (props.onSortToggle) {
      props.onSortToggle(col);
    }
    setSelectedColumnIndex(index);
  };

  const handleMultipleSelectable = (col: any, index: number) => {
    // setSelectedColumnIndex(index);
    alert('카테고리 필터링 기능은 추후에 구현 예정입니다.');
  };

  const handleCellClick = (row: any, col: any) => {
    setSelectedCell(
      col.isTextEditable
        ? {
            _id: row._id,
            name: col.name,
            text: row[col.name],
          }
        : null
    );
  };
  const handleCancel = (e: any) => {
    e.stopPropagation();
    // if(selectedCell.name === 'thumbnails'){
    //   const element = document.getElementsByClassName(`thumbnail-${selectedCell._id}`)[0];
    //   if(element instanceof HTMLImageElement){
    //     element.src = '';
    //   }
    // }
    setSelectedCell(null);

  };

  const handleSave = (e: any) => {
    e.stopPropagation();
    console.log('selectedCell: ', selectedCell);
    if (props.onInputSubmit) {
      props.onInputSubmit(selectedCell._id, selectedCell.name, selectedCell.text);
    }
    setSelectedCell(null);
  };

  const handleInputChange = (e: any) => {
    selectedCell.text = e.target.value;
    setSelectedCell(selectedCell);
  };

  const imageUpload = (id: string) => {
    // event.stopPropagation();
    console.log('selectedItem: ', id);
    if (props.imageUpload) {
      props.imageUpload(id);
    }

    setTimeout(()=> {

      setSelectedCell(null);

    }, 1000);

    // setSelectedCell(null);
  }

  const bannerImageUpload = (id: string, type: string) => {
    // event.stopPropagation();
    console.log('selectedItem: ', id, type);
    if (props.bannerImageUpload) {
      props.bannerImageUpload(id, type);
    }

    setTimeout(()=> {

      setSelectedCell(null);

    }, 1000);

    // setSelectedCell(null);
  }

  const openParentMetaGroup = (id: string, title: string, metaIds: string[]) => {
    // event.stopPropagation();
    console.log('selectedItem: ', id);
    console.log('selectedItem: ', metaIds);
    if (props.handleOpen) {
      props.handleOpen(id, title, metaIds);
    }

    // setTimeout(()=> {
    //
    //   setSelectedCell(null);
    //
    // }, 1000);

    // setSelectedCell(null);
  }

  const openIgnoreMetaGroup = (id: string, title: string, value: boolean) => {
    // event.stopPropagation();
    console.log('selectedItem: ', id);
    console.log('selectedItem: ', value);
    if (props.handleIgnoreOpen) {
      props.handleIgnoreOpen(id, title, value);
    }

    // setTimeout(()=> {
    //
    //   setSelectedCell(null);
    //
    // }, 1000);

    // setSelectedCell(null);
  }
  const openConfirmEmailMetaGroup = (id: string, title: string, value: boolean) => {
    // event.stopPropagation();
    console.log('selectedItem: ', id);
    console.log('selectedItem: ', value);
    if (props.handleConfirmEmailOpen) {
      props.handleConfirmEmailOpen(id, title, value);
    }

    // setTimeout(()=> {
    //
    //   setSelectedCell(null);
    //
    // }, 1000);

    // setSelectedCell(null);
  }

  const setVisibleDate = (date: any) => {
    alert(date);
  }


  const classes = useListStyles();

  const types = [{name: '큐레이션', value: 'curation'}, {name: '뉴스레터', value: 'newsletter'}, {name: '프로필', value: 'profile'}, {name: 'URL', value: 'url'}];
  const curationCategories = [{name: '크리에이터 추천', value: '643f95966901a2f90d648bef'}, {name: '요즘 뜨는 뉴스레터', value: '643f95fd6901a2f90d648bf0'}];

  return (
    <TableContainer className={classes.tableContainer} component={Paper} elevation={3}>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {props.columnInfo.map((col, index) => {
              let upDownArrow = null;
              if (!col.notSortable && selectedColumnIndex === index) {
                upDownArrow = col.isAscending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />;
              }
              return (
                <StyledTableCell
                  onClick={() => (col.isMultipleSelectable ? handleMultipleSelectable(col, index) : handleToggleSortClick(col, index))}
                  key={index}
                  align="left"
                >
                  <div className={classes.headerLabel}>
                    {col.label} {!col.isMultipleSelectable && upDownArrow}
                  </div>
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row: any, rowIndex: number) => {
            return (
              <TableRow style={{ background: rowIndex % 2 ? '#f6f6f6' : 'white' }} key={rowIndex.toString()}>
                {props.columnInfo.map((col, index) => {

                  if(col.name === 'ignore' && col.isTextEditable === false){
                    return (<TableCell key={index} style={col.style} align="left">{row[col.name] ? <CheckBoxIcon sx={{cursor: "pointer"}} onClick={() => openIgnoreMetaGroup(row._id, row.metaIds.join(','), row.ignore)}/> : <CheckBoxOutlineBlankIcon sx={{cursor: "pointer"}} onClick={() => openIgnoreMetaGroup(row._id, row.metaIds.join(','), row.ignore)}/>}</TableCell>);
                  }

                  if(col.name === 'welcomeEmailExisted' || col.name === 'confirmEmailExisted'){
                    return (<TableCell key={index} style={col.style} align="left">{row[col.name] ? <CheckBoxIcon sx={{cursor: "pointer"}} onClick={(e: any) => {
                      if (props.onInputSubmit) {
                        props.onInputSubmit(row._id, col.name, false);
                      }
                    }}/> : <CheckBoxOutlineBlankIcon sx={{cursor: "pointer"}} onClick={(e: any) => {
                      if (props.onInputSubmit) {
                        props.onInputSubmit(row._id, col.name, true);
                      }
                    }}/>}</TableCell>);
                  }

                  if (col.name === "visiblePeriod") {
                    return (
                      <TableCell key={index} style={col.style} align="left">
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                              renderInput={(props) => <TextField2 {...props} />}
                              label="시작일"
                              value={row.visibleStartDate || ""}
                              onChange={(newValue) => {
                                if (props.onInputSubmit) {
                                  props.onInputSubmit(row._id, "visibleStartDate", newValue);
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                              renderInput={(props) => <TextField2 {...props} />}
                              label="종료일"
                              value={row.visibleEndDate || ""}
                              onChange={(newValue) => {
                                if (props.onInputSubmit) {
                                  props.onInputSubmit(row._id, "visibleEndDate", newValue);
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </div>
                      </TableCell>
                    );
                  }

                  if (col.name === "identities") {
                    return (
                      <TableCell>
                        {row[col.name]
                          ? row[col.name].map((item: any, idx: number) => {
                              return (
                                <>
                                  {idx > 0 ? <br /> : ""}
                                  <span>
                                    {item.connection === "google-custom"
                                      ? "google"
                                      : item.connection === "Username-Password-Authentication"
                                      ? "email"
                                      : item.connection}
                                  </span>
                                </>
                              );
                            })
                          : "email"}
                      </TableCell>
                    );
                  }

                  // if(col.name === 'challengeRange.rangeStart'){
                  //   return (<TableCell>{(row['challengeRange']) ? row['challengeRange']["rangeStart"] : ""}</TableCell>);
                  // }
                  // if(col.name === 'challengeRange.rangeEnd'){
                  //   return (<TableCell>{(row['challengeRange']) ? row['challengeRange']["rangeEnd"] : ""}</TableCell>);
                  // }

                  if (typeof row[col.name] === "boolean") {
                    let disabled = false;
                    if (col.name === "isPremium") {
                      if (row.visible === false) disabled = true;
                    }
                    if (col.isTextEditable === false) {
                      return <TableCell>{row[col.name] ? <VisibilityIcon style={{ color: "green" }} /> : <VisibilityOffIcon />}</TableCell>;
                    }
                    return (
                      <TableCell onClick={(e) => handleCellClick(row, col)} key={index} style={col.style} align="left">
                        <FormControlLabel
                          style={{ marginRight: 0 }}
                          control={
                            <Switch
                              disabled={disabled}
                              checked={!!row[col.name]}
                              onChange={(e: any) => {
                                if (props.onInputSubmit) {
                                  props.onInputSubmit(row._id, col.name, e.target.checked);
                                }
                              }}
                              name="verified"
                              color="secondary"
                            />
                          }
                          label={!!row[col.name] ? "Yes" : "No"}
                        />
                      </TableCell>
                    );
                  } else if (col.isMultipleSelectable) {
                    return (
                      <TableCell key={index} style={col.style} align="left">
                        <MultipleSelector
                          selected={row[col.name]}
                          menuList={props.multipleSelectList}
                          label={col.label}
                          onCheckboxSelectChange={(selected: any) => {
                            if (props.onMultipleSelectCheckboxChange) {
                              props.onMultipleSelectCheckboxChange(row._id, col.name, selected);
                            }
                          }}
                        />
                      </TableCell>
                    );
                  } else if (col.isSingleSelectable) {
                    return (
                      <TableCell key={index} style={col.style} align="left">
                        <Select
                          size="small"
                          sx={{ fontSize: "0.8rem" }}
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={row[col.name] || ""}
                          onChange={(e: any) => {
                            if (props.onInputSubmit) {
                              // alert(e.target.value);
                              props.onInputSubmit(row._id, col.name, e.target.value);
                            }
                          }}
                          label="선택하세요"
                        >
                          <MenuItem value="" sx={{ fontSize: "0.8rem" }}>
                            <em>None</em>
                          </MenuItem>
                          {col.name !== "curationCategoryId" &&
                            col.name !== "categoryId" &&
                            types.map((type: any) => (
                              <MenuItem key={type.value} value={type.value} sx={{ fontSize: "0.8rem" }}>
                                {type.name}
                              </MenuItem>
                            ))}
                          {col.name === "curationCategoryId" &&
                            props.curationCategoryList.map((type: any) => (
                              <MenuItem key={type.value} value={type.value} sx={{ fontSize: "0.8rem" }}>
                                {type.name}
                              </MenuItem>
                            ))}
                          {col.name === "categoryId" &&
                            props.stickerCategoryList.map((type: any) => (
                              <MenuItem key={type.value} value={type.value} sx={{ fontSize: "0.8rem" }}>
                                {type.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </TableCell>
                    );
                  }
                  const isSelected = selectedCell?._id === row._id && selectedCell?.name === col.name;
                  return (
                    <TableCell
                      onClick={(e) => handleCellClick(row, col)}
                      key={index}
                      style={{ ...col.style, cursor: col.isTextEditable ? "pointer" : "inherit" }}
                      align="left"
                    >
                      {isSelected ? (
                        <OutlinedInput
                          id={row._id}
                          autoFocus
                          multiline
                          fullWidth={true}
                          defaultValue={
                            (col.name === "tags" || col.name === "metaIds" || col.name === "metaGroupIds") && row[col.name]
                              ? row[col.name].join("\n")
                              : row[col.name]
                          }
                          name={col.name}
                          onChange={handleInputChange}
                          endAdornment={
                            <>
                              <InputAdornment onClick={handleCancel} position="end">
                                <IconButton style={{ padding: 0 }}>
                                  <CancelIcon />
                                </IconButton>
                              </InputAdornment>
                              <InputAdornment onClick={handleSave} position="end">
                                <IconButton style={{ padding: 0 }}>
                                  <DoneIcon />
                                </IconButton>
                              </InputAdornment>
                            </>
                          }
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{ style: { fontSize: 12 } }}
                          labelWidth={0}
                        />
                      ) : col.name === "challengeRange.rangeStart" ? (
                        <>{row["challengeRange"] ? row["challengeRange"]["rangeStart"] : ""}</>
                      ) : col.name === "challengeRange.rangeEnd" ? (
                        <>{row["challengeRange"] ? row["challengeRange"]["rangeEnd"] : ""}</>
                      ) : (
                        <>
                          {col.name === "metaGroupIds" && row[col.name]
                            ? row["metaGroupNames"]?.map((metaName: string) => (
                                <span>
                                  {metaName}
                                  <br />
                                </span>
                              ))
                            : col.name === "myNewsletterChannelIds" && row[col.name]
                            ? row["channelNames"]?.map((metaName: string) => (
                                <span>
                                  {metaName}
                                  <br />
                                </span>
                              ))
                            : (col.name === "tags" || col.name === "metaIds") && row[col.name]
                            ? row[col.name].map((metaId: string) => (
                                <span>
                                  {metaId}
                                  <br />
                                </span>
                              ))
                            : col.name === "content" && row[col.name]
                            ? row[col.name].split("\n").map((str: string) => <div>{str}</div>)
                            : row[col.name]}
                        </>
                      )}

                      {col.name === "title" && row["parentMetaGroupId"] && <span style={{ color: "orangered" }}>[Merged]</span>}

                      {col.name === "metaIds" && row["removedMetaIds"] && (
                        <div style={{ textDecoration: "line-through", textDecorationColor: "orangered" }}>
                          {row["removedMetaIds"]?.map((metaId: string) => (
                            <span>
                              {metaId}
                              <br />
                            </span>
                          ))}
                        </div>
                      )}

                      {col.name === "thumbnails-preview" && (
                        <div>
                          <img
                            className={`thumbnails-${row._id}`}
                            src={row["thumbnails"]}
                            style={{ width: "50px" }}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          />
                        </div>
                      )}

                      {col.name === "thumbnails" && (
                        <>
                          <div>
                            <img className={`thumbnail-${row._id}`} src="" />
                          </div>
                          <div>
                            <button onClick={() => {}}>URL입력</button>
                            <button onClick={() => imageUpload(row._id)}>이미지업로드</button>
                          </div>
                          {/*<div><img className={`${col.name}-${row._id}`} src={row[col.name]} style={{width: '50px'}} onClick={(event) => {event.stopPropagation();}}/></div>*/}
                        </>
                      )}
                      {col.name === "imageUrl-preview" && (
                        <div>
                          <img
                            className={`imageUrl-${row._id}`}
                            src={row["imageUrl"]}
                            style={{ width: "50px" }}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          />
                        </div>
                      )}
                      {col.name === "imageUrl" && (
                        <>
                          <div>
                            <img className={`imageUrl-${row._id}`} src="" />
                          </div>
                          <div>
                            <button onClick={() => {}}>URL입력</button>
                            <button onClick={() => imageUpload(row._id)}>이미지업로드</button>
                          </div>
                          {/*<div><img className={`${col.name}-${row._id}`} src={row[col.name]} style={{width: '50px'}} onClick={(event) => {event.stopPropagation();}}/></div>*/}
                        </>
                      )}
                      {(col.name === "slimImageUrl" || col.name === "homeImageUrl" || col.name === "bgImageUrl") && (
                        <>
                          <div>
                            <button
                              onClick={(event) => {
                                event.stopPropagation();
                                bannerImageUpload(row._id, col.name);
                              }}
                            >
                              이미지업로드
                            </button>
                          </div>
                          <div style={{ position: "relative" }}>
                            <img
                              className={`${col.name}-${row._id}`}
                              src={row[col.name]}
                              style={{ width: "200px" }}
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                            />
                          </div>
                          {col.name === "bgImageUrl" && row[col.name] && (
                            <>
                              <div style={{ position: "relative", top: "-80px", left: "10px", color: "#3fc67d", fontSize: "11px", fontWeight: "600" }}>
                                {row["title"]}
                              </div>
                              <div
                                style={{
                                  position: "relative",
                                  top: "-80px",
                                  left: "10px",
                                  paddingRight: "40px",
                                  color: "white",
                                  fontSize: "15px",
                                  fontWeight: "600",
                                }}
                              >
                                {row["categoryName"].split("\n").map((item: string, idx: number) => {
                                  return (
                                    <span key={idx}>
                                      {item}
                                      <br />
                                    </span>
                                  );
                                })}
                              </div>
                            </>
                          )}
                          {/*<div>*/}
                          {/*  <button onClick={() => {}}>URL입력</button>*/}
                          {/*  <button onClick={() => imageUpload(row._id)}>이미지업로드</button>*/}
                          {/*</div>*/}
                        </>
                      )}
                      {col.name === "iconImageUrl" && (
                        <>
                          <div>
                            <button
                              onClick={(event) => {
                                event.stopPropagation();
                                bannerImageUpload(row._id, col.name);
                              }}
                            >
                              이미지업로드
                            </button>
                          </div>
                          <div style={{ position: "relative" }}>
                            {row[col.name] && (
                              <>
                                <div style={{ width: "200px", height: "38px", paddingLeft: "12px", backgroundColor: `${row["bgColor"] || "gray"}` }}>
                                  <img
                                    className={`${col.name}-${row._id}`}
                                    src={row[col.name]}
                                    style={{ height: "38px" }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    position: "relative",
                                    top: "-35px",
                                    left: "60px",
                                    width: "150px",
                                    paddingRight: 0,
                                    color: `${row["fontColor"] || "white"}`,
                                    fontSize: "11px",
                                    fontWeight: "800",
                                  }}
                                >
                                  {row["content"]
                                    ? row["content"].split("\n").map((item: string, idx: number) => {
                                        return (
                                          <span key={idx}>
                                            {item}
                                            <br />
                                          </span>
                                        );
                                      })
                                    : ""}
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      )}
                      {col.name === "parentMetaGroupId" && !row[col.naimageUploadme] && (
                        <>
                          {/*<div>{row.parentMetaGroupId}</div>*/}
                          <div>
                            <Button onClick={() => openParentMetaGroup(row._id, row.title, row.metaIds)}>설정</Button>
                            {/*<PopupExample title={row.title} id={row._id}/>*/}
                            {/*<button onClick={() => imageUpload(row._id)}>설정</button>*/}
                          </div>
                        </>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>

        {props.buttons && (
          <TableBody>
            <TableRow>
              <TableCell colSpan={100}>{props.buttons.map((buttonElement: any) => buttonElement)}</TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};