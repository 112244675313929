import { useState, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { setAccessToken } from "../utils/auth";
import { getPremiumSettlement, putPremiumSettlement } from "../utils/api";
import { Settlement } from "../utils/api.d";
import moment from "moment-timezone";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Button } from "@material-ui/core";

interface Data {
  _id: string;
  channelId: string;
  title: string;
  myViewCount: number;
  myAmount: number;
  carryoverAmount: number;
  status: string;
  depositDate: string;
  submit: boolean;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: any, b: any) => number {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  align?: "right" | "left" | "center";
}

const headCells: readonly HeadCell[] = [
  {
    id: "channelId",
    disablePadding: true,
    label: "뉴스레터 채널 ID",
  },
  {
    id: "title",
    disablePadding: false,
    label: "뉴스레터 제목",
  },
  {
    id: "myViewCount",
    disablePadding: false,
    label: "조회 수",
  },
  {
    id: "myAmount",
    disablePadding: false,
    label: "정산 금액",
  },
  {
    id: "carryoverAmount",
    disablePadding: false,
    label: "이월 금액",
  },
  {
    id: "status",
    disablePadding: false,
    label: "상태",
  },
  {
    id: "depositDate",
    disablePadding: false,
    align: "center",
    label: "입금일",
  },
  {
    id: "submit",
    disablePadding: false,
    align: "center",
    label: "입금 완료 처리",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align || "left"} padding={"normal"} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function PremiumSettlement() {
  const { getAccessTokenSilently } = useAuth0();

  const [list, setList] = useState<
    {
      targetMonth: string;
      settlements: Settlement[];
    }[]
  >([]);

  useEffect(() => {
    constructUserStat();
  }, []);

  const constructUserStat = async () => {
    const token = await getAccessTokenSilently();
    setAccessToken(token);

    const list = await getPremiumSettlement();
    setList(list.settlementList);
  };

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("myAmount");
  const [selected, setSelected] = useState<readonly string[]>([]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // const newSelected = rows.map((n) => n.id);
      // setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const getStatus = (status: string, carryover: boolean) => {
    if (carryover) {
      return "이월 누적";
    }

    if (status === "settled") {
      return "정산 완료";
    }
    if (status === "deposited") {
      return "입금 완료";
    } else {
      return "정산 중";
    }
  };

  const onDepositDateChange = async (index: number, id: string, newValue?: string | null) => {
    if (!newValue) return;

    setList((prev) => {
      const newList = [...prev];
      const target = newList[index];
      if (!target) return prev;
      const item = target.settlements.find((item) => item._id === id);
      if (!item) return prev;
      item.depositDate = new Date(newValue).toISOString();
      return newList;
    });

    // await putPremiumSettlement({ _id: id, depositDate: new Date(newValue) });
  };

  const onSubmit = async (index: number, id: string, depositDate: string | undefined) => {
    if (!depositDate) {
      alert("입금일을 선택해주세요.");
      return;
    }

    if (window.confirm("정말 입금 완료 처리하시겠습니까??") === true) {
      //확인
      await putPremiumSettlement({ _id: id, depositDate, status: "deposited" });

      setList((prev) => {
        const newList = [...prev];
        const target = newList[index];
        if (!target) return prev;

        const item = target.settlements.find((item) => item._id === id);
        if (!item) return prev;

        item.status = "deposited";

        return newList;
      });
    } else {
      //취소
      return;
    }
  };

  return (
    <div style={{ paddingTop: "30px" }} className="premium-settlement">
      {(list || []).map((item, i) => {
        let visibleRows = item.settlements.sort(getComparator(order, orderBy));

        const allItem = visibleRows.find((row) => row.channelId === "all");
        visibleRows = visibleRows.filter((row) => row.channelId !== "all");

        return (
          <Accordion defaultExpanded={i === 0} key={i} style={{ marginBottom: "10px", width: "1300px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ fontWeight: "700", borderBottom: "1px solid #e0e0e0" }}
            >
              <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>{moment(item.targetMonth).format("YYYY년 MM월")}</Typography>
            </AccordionSummary>
            <div style={{ display: "flex", flexDirection: "column", gap: "4px", padding: "4px 16px" }}>
              <div>• 총 멤버십 결제 금액 : {allItem?.originTotalAmount.toLocaleString() || "-"} 원</div>
              <div>• 총 정산 금액 : {allItem?.totalAmount.toLocaleString() || "-"} 원</div>
              <div>• 총 조회 수 : {allItem?.totalViewCount.toLocaleString() || "-"} 회</div>
              <div style={{ color: "#999", fontWeight: "bold" }}>{"(정산 데이터는 매일 13시, 21시에 업데이트 됩니다.)"}</div>
            </div>
            <AccordionDetails>
              <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "100%", mb: 2, fontSize: "12px" }}>
                  <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={item.settlements.length}
                      />
                      <TableBody>
                        {visibleRows.map((row, index) => {
                          const isItemSelected = selected.includes(row._id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row._id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row._id}
                              selected={isItemSelected}
                              sx={{ cursor: "pointer" }}
                            >
                              {/* <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell> */}
                              <TableCell component="th" id={labelId} scope="row">
                                {row.channelId}
                              </TableCell>
                              <TableCell component="th" id={labelId} scope="row">
                                {row.title}
                              </TableCell>
                              <TableCell align="right">{row.myViewCount.toLocaleString()}</TableCell>
                              <TableCell align="right">{row.myAmount.toLocaleString()}</TableCell>
                              <TableCell align="right">{row.carryoverAmount.toLocaleString()}</TableCell>
                              <TableCell align="center">{getStatus(row.status, row.myAmount + row.carryoverAmount < 1000)}</TableCell>
                              <TableCell align="center" onClick={(e) => e.stopPropagation()}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    disabled={row.status === "deposited" || row.myAmount + row.carryoverAmount < 1000}
                                    value={row.depositDate}
                                    onChange={(newValue) => {
                                      onDepositDateChange(i, row._id, newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </LocalizationProvider>
                              </TableCell>
                              <TableCell align="center" onClick={(e) => e.stopPropagation()}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={row.status === "deposited" || row.myAmount + row.carryoverAmount < 1000}
                                  onClick={() => onSubmit(i, row._id, row.depositDate)}
                                >
                                  입금 완료 처리
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
          <Typography>Header</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.</Typography>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
}
