import { useAuth0 } from '@auth0/auth0-react';
import { FormControlLabel, Switch, TextField } from '@material-ui/core';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import qs from 'qs';
import { RouteComponentProps } from 'react-router-dom';
import { FileUploader } from "file-uploader-react-component";
import {
    getNewsletter,
    getCurationList,
    putNewsletter,
    putCuration,
    getCurationCategoryList,
    postSticker, postCuration
} from '../utils/api';
import { accessToken, setAccessToken } from '../utils/auth';
import { MainList } from './MainList';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const columnInfo = [
  {
    label: "노출순서",
    name: "order",
    isAscending: true,
    isTextEditable: true,
    style: { minWidth: 110 },
  },
  {
    label: "ID",
    name: "_id",
    style: { maxWidth: 220 },
    isAscending: false,
  },
  {
    label: "발행일자",
    name: "publishedDate",
    isAscending: false,
    isTextEditable: true,
    style: { minWidth: 190 },
  },
  {
    label: "분류명(old)",
    name: "title",
    isAscending: false,
    isTextEditable: true,
    style: { minWidth: 200 },
  },
  {
    label: "카테고리",
    name: "curationCategoryId",
    style: { minWidth: 100 },
    isSingleSelectable: true,
  },
  {
    label: "제목",
    name: "categoryName",
    isAscending: false,
    isTextEditable: true,
    style: { minWidth: 250 },
  },
  {
    label: "설명",
    name: "description",
    style: { minWidth: 300 },
    isTextEditable: true,
  },
  {
    label: "배경 이미지",
    name: "bgImageUrl",
    style: { minWidth: 150, maxWidth: 250 },
    isTextEditable: true,
  },
  {
    label: "배경 색상",
    name: "bgColor",
    style: { minWidth: 150 },
    isTextEditable: true,
  },

  {
    label: "노출",
    name: "visible",
    isAscending: false,
  },
  {
    label: "홈 노출",
    name: "homeUsed",
    isAscending: false,
  },

  {
    label: "뉴스레터",
    name: "metaGroupIds",
    style: { minWidth: 300 },
    isTextEditable: true,
    // isMultipleSelectable: true,
  },
  {
    label: "최초 등록 일시",
    name: "createdAt",
    isAscending: false,
    style: { minWidth: 120 },
  },
  {
    label: "최근 수정 일시",
    name: "updatedAt",
    isAscending: false,
    style: { minWidth: 120 },
  },
];

let curationList: any = [];
let curationCategoryList: any = [];
let metadataList: any = [];
export const CurationList = () => {
    const [items, setItems] = useState<any>([]);
    const { getAccessTokenSilently } = useAuth0();

    // const [locale, setLocale] = React.useState<Locale | null>(null)
    const [name, setName] = React.useState('banners');
    const [url, setUrl] = React.useState('https://mqnddbigxf.execute-api.ap-northeast-2.amazonaws.com/v1/upload');
    const [method, setMethod] = React.useState('POST');

    const [newCurationTitle, setNewCurationTitle] = useState('');

    // const [page, setPage] = React.useState('1');
    // const [limit, setLimit] = React.useState('100');

    let page = '1';
    let limit = '1000';
    let search = '';

    const inputRef = useRef<HTMLInputElement>(null);
    const divRef = useRef<HTMLDivElement>(null);

    // const inputEl = useRef();
    //

    let selectedId = '';
    let selectedType = '';

    const bannerImageUpload = (id: string, type: string): void => {
        if (!divRef.current) {
            return;
        }

        selectedId = id;
        selectedType = type;

        const element = document.getElementsByClassName('file-uploader-container')[0].children[1];
        if (element instanceof HTMLElement) {
            element.click();
        }
    }

    const fileUploaded = (response: any) => {
        const jsonObj = JSON.parse(response);

        handleInputSubmit(selectedId, selectedType, jsonObj.original);

        // const element = document.getElementsByClassName(`thumbnail-${selectedId}`)[0];
        // if(element instanceof HTMLImageElement){
        //     console.log('String');
        //     setTimeout(()=> {
        //
        //         const textarea = document.getElementById(selectedId);
        //         if(textarea instanceof HTMLTextAreaElement){
        //             textarea.value = jsonObj.large;
        //         }
        //
        //         element.src = jsonObj.large;
        //         element.width = 100;
        //
        //     }, 4000);
        // }

    }
    const fileGot = (file: File | Blob) => {

    }

    const beforeRequest= (request: XMLHttpRequest, formData: FormData) => {
        console.log('access token : ', accessToken);
        request.setRequestHeader('Authorization', `Bearer ${accessToken}` );
        // request.setRequestHeader('Authorization', 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjBwdmRfVkxqSW9kb21XaWlXOWZmYiJ9.eyJodHRwczovL2FkbWluLmhleWJ1bm55LmlvL3JvbGVzIjpbImFkbWluIl0sImlzcyI6Imh0dHBzOi8vYWNjb3VudHMuaGV5YnVubnkuaW8vIiwic3ViIjoiYXV0aDB8NjA3NGU0MTdiMDBmYTYwMDY5OGE3ZjA1IiwiYXVkIjpbImh0dHBzOi8vYXBpMy5oZXlidW5ueS5pbyIsImh0dHBzOi8vdHdpZ2Zhcm0uZXUuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTYyODc1MDUxNywiZXhwIjoxNjI4ODM2OTE3LCJhenAiOiJIQ1JGakd6QVlUZ0JjSlpFRThKYzRqZ29RUHlHd2tCaCIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwifQ.BBLkOgQ1aoIPTSiALj-6D5qHwzK3TdKnJfhhejTOP-rGPT7FChMS4TFvwWS3-P961dAckAcIoGwNraq2ncyV8vg_QHJ7jsPb9zyTVCot3DErLhWsJe1kRHwNtzI-ICNPqkp4n_pH7RTwpVlX6ah-_gPXKhMQdDuE9vK3ZOrstNM0eLcMkcMc9Mi8ANkQSSpAsSmCg8iJWQMjeEEBE2VwDAJUOHc8u5gcCHolldmkJbAY7mf_sRBwknU3gGjRrrG-z_zG-JobBJwAgJjUmUp63WBSm_9waQHp7Pu4L7jC47Lx1gLltdyQ8L3QWSKfBC8bPnU0QmQ8i0dzOfMUSl6bJg');
        formData.append("type", 'banner');
        // return Promise.resolve(formData);
    }

    const constructList = async (isReload: boolean = false) => {
        if (isReload || curationList.length === 0) {
            setAccessToken(await getAccessTokenSilently());
            const result = await getCurationList({params: {page: page, limit: limit, search: search}});

            const curationCount = result.length;
            // console.log("newsletterCount", newsletterCount);

            curationList = result.curationList.map((curation: any, index: number) => {
                return { ...curation, ...(curation.homeUsed === undefined ? {homeUsed: false}:{}), orderNo: curationCount - index };
            });
            metadataList = result.metadataList;

        }

        const categoryObject = await getCurationCategoryList({params: {'list': 1}});
        const categoryList = categoryObject?.categoryList || [];
        curationCategoryList = categoryList.map((category: any) => {
            return { name: category.name, value : category._id };
        });
        // categoryList = result.categoryList.filter((elem: any) => !elem.hasOwnProperty('isPromotion') || elem.isPromotion !== true );

        setItems(
            curationList.map((item: any, index: number) => {
                return {
                    ...item,
                    createdAt: moment(item.createdAt).format('YYYY.MM.DD HH:mm:ss'),
                    updatedAt: moment(item.updatedAt).format('YYYY.MM.DD HH:mm:ss'),
                    metaGroupNames:
                        item.metaGroupIds?.map((metaId: any) => {
                            const index = metadataList.findIndex((meta: any) => meta._id === metaId);
                            // if (index === -1) {
                                console.log('index: ', index, 'index: ', metadataList[index]?.title);
                            // }
                            return metadataList[index]?.title;
                            // return {
                            //     _id: metaId,
                            //     mataName: metadataList[index]?.title,
                            //     // mataName: 'item',
                            // };
                            // return item._id;
                        }) || [],
                    // curationCategoryList: curationCategoryList?.categoryList || [],
                };
            })
        );
    };

    useEffect(() => {
        constructList();
    }, []);


    const changePage = () => {

        const pageinput = document.getElementById('input_page_number');
        if(pageinput instanceof HTMLInputElement){
            page = pageinput.value;
        }


        const limitinput = document.getElementById('input_page_limit');
        if(limitinput instanceof HTMLInputElement){
            limit = limitinput.value;
        }

        const searchinput = document.getElementById('input_search');
        if(searchinput instanceof HTMLInputElement){
            search = searchinput.value;
        }

        setTimeout(()=> {
            constructList(true);
        }, 500);

    };

    const sort = (col: any) => {
        const index = columnInfo.findIndex((e) => e.name === col.name);
        columnInfo[index].isAscending = !columnInfo[index].isAscending;
        const sortedItems = [...curationList].sort((a: any, b: any) => {
            if (typeof a[col.name] === 'number' || typeof a[col.name] === 'boolean') {
                return columnInfo[index].isAscending ? a[col.name] - b[col.name] : b[col.name] - a[col.name];
            }

            if (!a[col.name]) {
                a[col.name] = '';
            }
            if (!b[col.name]) {
                b[col.name] = '';
            }

            return columnInfo[index].isAscending ? a[col.name].localeCompare(b[col.name]) : b[col.name].localeCompare(a[col.name]);
        });
        curationList = sortedItems;
        // setItems(sortedItems);
        constructList();
    };

    const handleInputSubmit = async (id: string, name: string, value: any) => {
        const index = curationList.findIndex((newsletter: any) => newsletter._id === id);
        if(name==='metaGroupIds') {
            value = value.split('\n');
            value = value.map((item: any)=> item.trim());
        }

        curationList[index][name] = value;
        console.log(id, name, value);
        constructList();
        await putCuration(id, { [name]: value });

        if (name === 'bgImageUrl') {
            const element = document.getElementsByClassName(`${name}-${id}`)[0];
            if (element instanceof HTMLImageElement) {
                console.log('String');
                element.src = value;
            }
        }
    };

    const handleNewsletterChange = async (id: string, name: string, value: any) => {
        console.log('selectedList: ', value);
        const index = curationList.findIndex((newsletter: any) => newsletter._id === id);
        const valueToSave = value.map((category: any) => category._id);
        curationList[index][name] = valueToSave;
        constructList();
        await putNewsletter(id, { [name]: valueToSave });
    };

    const handleAddCuration = async() => {
        console.log('newCurationTitle', newCurationTitle);

        if(!newCurationTitle){
            return;
        }

        await postCuration({
            "thumbnails": "",
            "order": 999,
            "visible": false,
            "newsletters": [],
            "categoryName": "",
            "metaGroupIds": [],
            "bannerImageUrl": "",
            "title": newCurationTitle,
            "description": "",
            "curationCategoryId": "",
            "bgImageUrl": "",
            "homeUsed": true
        });

        setNewCurationTitle('');
        alert('신규 큐레이션이 추가되었습니다.');

        setTimeout(()=> {
            constructList(true);
        }, 500);

        // setDefaultValue('');
    }

    const [targetId, setTargetId] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [targetMetaGroup, setTargetMetaGroup] = React.useState({_id: '', title: '', metaIds: []});
    const [open, setOpen] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);

    return (
        <div>
            <h1>큐레이션 관리</h1>

            <div className="uploader-wrapper" ref={divRef} style={{display: 'none'}}>
                <FileUploader fileUploaded={fileUploaded}
                              fileGot={fileGot}
                              accept='image/*'
                              multiple={false}
                    // locale={locale}
                              name={name}
                              url={url}
                              method={method}
                              beforeRequest= {beforeRequest}
                >
                </FileUploader>
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '10px'}}>
                <Paper
                    component="form"
                    sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
                >
                    <Typography>
                        신규 큐레이션 추가
                    </Typography>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        // value={defaultValue}
                        placeholder="큐레이션 제목"
                        inputProps={{ "aria-label": "new curation title" }}
                        onChange={event=>{                                 //adding the onChange event
                            setNewCurationTitle(event.target.value)
                        }}
                    />
                    <IconButton sx={{ p: "10px" }} aria-label="add">
                        <AddIcon onClick={handleAddCuration} />
                    </IconButton>
                </Paper>
            </div>

            <MainList
                onSortToggle={sort}
                onInputSubmit={handleInputSubmit}
                columnInfo={columnInfo}
                listData={items}
                multipleSelectList={metadataList}
                onMultipleSelectCheckboxChange={handleNewsletterChange}
                buttons={[]}
                // imageUpload={imageUpload}
                // handleOpen={handleOpen}
                bannerImageUpload={bannerImageUpload}
                curationCategoryList={curationCategoryList}
            />
        </div>
    );
};
