import { useAuth0 } from '@auth0/auth0-react';
import { FormControlLabel, Switch, TextField } from '@material-ui/core';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import qs from 'qs';
import { RouteComponentProps } from 'react-router-dom';
import { FileUploader } from "file-uploader-react-component";
import {getNewsletter, getBannerList, putNewsletter, putBanner, postSlimBanner, postCurationCategory} from '../utils/api';
import { accessToken, setAccessToken } from '../utils/auth';
import { MainList } from './MainList';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const columnInfo = [
    {
        label: '노출순서',
        name: 'order',
        isAscending: true,
        isTextEditable: true,
        style: { minWidth: 110 },
    },

    {
        label: '배너명',
        name: 'title',
        isAscending: false,
        isTextEditable: true,
        style: { minWidth: 220 },
    },

    {
        label: '배너 내용',
        name: 'content',
        style: { minWidth: 200 },
        isTextEditable: true,
    },

    {
        label: '노출여부',
        name: 'visible',
        isAscending: false,
    },
    {
        label: '노출기간',
        name: 'visiblePeriod',
        isAscending: false,
        style: { minWidth: 250 },
    },
    // {
    //     label: '노출(종료일)',
    //     name: 'visibleEndDate',
    //     isAscending: false,
    //     style: { minWidth: 120 },
    // },
    {
        label: '필터(사용자유형)',
        name: 'filterUserType',
        style: { minWidth: 220 },
        isTextEditable: true,
    },

    {
        label: '배너 배경색(16진수)',
        name: 'bgColor',
        style: { minWidth: 180 },
        isTextEditable: true,
    },
    {
        label: '배너 폰트색(16진수)',
        name: 'fontColor',
        style: { minWidth: 180 },
        isTextEditable: true,
    },
    {
        label: '배너 이미지(new)',
        name: 'iconImageUrl',
        style: { minWidth: 150, maxWidth: 250 },
        isTextEditable: true,
    },

    {
        label: '배너 이미지(old)',
        name: 'slimImageUrl',
        style: { minWidth: 150, maxWidth: 250 },
        isTextEditable: true,
    },
    // {
    //     label: '배너 이미지(확장)',
    //     name: 'homeImageUrl',
    //     style: { minWidth: 150, maxWidth: 250 },
    //     isTextEditable: true,
    // },
    {
        label: '배너 타입',
        name: 'type',
        style: { minWidth: 100 },
        isSingleSelectable: true,
    },
    {
        label: '연관 컨텐츠 ID',
        name: 'typeContentId',
        style: { minWidth: 200 },
        isTextEditable: true,
    },

    {
        label: '최초 등록 일시',
        name: 'createdAt',
        isAscending: false,
        style: { minWidth: 120 },
    },
    {
        label: '최근 수정 일시',
        name: 'updatedAt',
        isAscending: false,
        style: { minWidth: 120 },
    },

    {
        label: 'ID',
        name: '_id',
        style: { maxWidth: 150 },
        isAscending: false,
    },


];

let bannerList: any = [];
let metadataList: any = [];
export const BannerList = () => {
    const [items, setItems] = useState<any>([]);
    const { getAccessTokenSilently } = useAuth0();

    // const [locale, setLocale] = React.useState<Locale | null>(null)
    const [name, setName] = React.useState('banners');
    const [url, setUrl] = React.useState('https://mqnddbigxf.execute-api.ap-northeast-2.amazonaws.com/v1/upload');
    const [method, setMethod] = React.useState('POST');

    // const [page, setPage] = React.useState('1');
    // const [limit, setLimit] = React.useState('100');

    const [newBannerTitle, setNewBannerTitle] = useState('');
    const [defaultValue, setDefaultValue] = useState('');

    let page = '1';
    let limit = '1000';
    let search = '';

    const inputRef = useRef<HTMLInputElement>(null);
    const divRef = useRef<HTMLDivElement>(null);

    // const inputEl = useRef();
    //

    let selectedId = '';
    let selectedType = '';

    const bannerImageUpload = (id: string, type: string): void => {
        if (!divRef.current) {
            return;
        }

        selectedId = id;
        selectedType = type;

        const element = document.getElementsByClassName('file-uploader-container')[0].children[1];
        if (element instanceof HTMLElement) {
            element.click();
        }
    }

    const beforeRequest= (request: XMLHttpRequest, formData: FormData) => {
        console.log('access token : ', accessToken);
        request.setRequestHeader('Authorization', `Bearer ${accessToken}` );
        // request.setRequestHeader('Authorization', 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjBwdmRfVkxqSW9kb21XaWlXOWZmYiJ9.eyJodHRwczovL2FkbWluLmhleWJ1bm55LmlvL3JvbGVzIjpbImFkbWluIl0sImlzcyI6Imh0dHBzOi8vYWNjb3VudHMuaGV5YnVubnkuaW8vIiwic3ViIjoiYXV0aDB8NjA3NGU0MTdiMDBmYTYwMDY5OGE3ZjA1IiwiYXVkIjpbImh0dHBzOi8vYXBpMy5oZXlidW5ueS5pbyIsImh0dHBzOi8vdHdpZ2Zhcm0uZXUuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTYyODc1MDUxNywiZXhwIjoxNjI4ODM2OTE3LCJhenAiOiJIQ1JGakd6QVlUZ0JjSlpFRThKYzRqZ29RUHlHd2tCaCIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwifQ.BBLkOgQ1aoIPTSiALj-6D5qHwzK3TdKnJfhhejTOP-rGPT7FChMS4TFvwWS3-P961dAckAcIoGwNraq2ncyV8vg_QHJ7jsPb9zyTVCot3DErLhWsJe1kRHwNtzI-ICNPqkp4n_pH7RTwpVlX6ah-_gPXKhMQdDuE9vK3ZOrstNM0eLcMkcMc9Mi8ANkQSSpAsSmCg8iJWQMjeEEBE2VwDAJUOHc8u5gcCHolldmkJbAY7mf_sRBwknU3gGjRrrG-z_zG-JobBJwAgJjUmUp63WBSm_9waQHp7Pu4L7jC47Lx1gLltdyQ8L3QWSKfBC8bPnU0QmQ8i0dzOfMUSl6bJg');
        formData.append("type", 'banner');
        // return Promise.resolve(formData);
    }

    const fileUploaded = (response: any) => {
        const jsonObj = JSON.parse(response);

        handleInputSubmit(selectedId, selectedType, jsonObj.original);

        // const element = document.getElementsByClassName(`thumbnail-${selectedId}`)[0];
        // if(element instanceof HTMLImageElement){
        //     console.log('String');
        //     setTimeout(()=> {
        //
        //         const textarea = document.getElementById(selectedId);
        //         if(textarea instanceof HTMLTextAreaElement){
        //             textarea.value = jsonObj.large;
        //         }
        //
        //         element.src = jsonObj.large;
        //         element.width = 100;
        //
        //     }, 4000);
        // }

    }
    const fileGot = (file: File | Blob) => {

    }

    let visible = '';

    const constructList = async (isReload: boolean = false) => {
        if (isReload || bannerList.length === 0) {
            setAccessToken(await getAccessTokenSilently());
            const result = await getBannerList({params: {page: page, limit: limit, search: search, ...(visible !== '' ? {visible} : {})}});

            const bannerCount = result.length;
            // console.log("newsletterCount", newsletterCount);

            bannerList = result.bannerList.map((banner: any, index: number) => {
                return { ...banner, orderNo: bannerCount - index };
            });
            metadataList = result.metadataList;

        }

        setItems(
            bannerList.map((item: any, index: number) => {
                return {
                    ...item,
                    createdAt: moment(item.createdAt).format('YYYY.MM.DD HH:mm:ss'),
                    updatedAt: moment(item.updatedAt).format('YYYY.MM.DD HH:mm:ss'),
                };
            })
        );
    };

    useEffect(() => {
        constructList();
    }, []);


    // const changePage = () => {
    //
    //     const pageinput = document.getElementById('input_page_number');
    //     if(pageinput instanceof HTMLInputElement){
    //         page = pageinput.value;
    //     }
    //
    //
    //     const limitinput = document.getElementById('input_page_limit');
    //     if(limitinput instanceof HTMLInputElement){
    //         limit = limitinput.value;
    //     }
    //
    //     const searchinput = document.getElementById('input_search');
    //     if(searchinput instanceof HTMLInputElement){
    //         search = searchinput.value;
    //     }
    //
    //     setTimeout(()=> {
    //         constructList(true);
    //     }, 500);
    //
    // };

    const sort = (col: any) => {
        const index = columnInfo.findIndex((e) => e.name === col.name);
        columnInfo[index].isAscending = !columnInfo[index].isAscending;
        const sortedItems = [...bannerList].sort((a: any, b: any) => {
            if (typeof a[col.name] === 'number' || typeof a[col.name] === 'boolean') {
                return columnInfo[index].isAscending ? a[col.name] - b[col.name] : b[col.name] - a[col.name];
            }

            if (!a[col.name]) {
                a[col.name] = '';
            }
            if (!b[col.name]) {
                b[col.name] = '';
            }

            return columnInfo[index].isAscending ? a[col.name].localeCompare(b[col.name]) : b[col.name].localeCompare(a[col.name]);
        });
        bannerList = sortedItems;
        // setItems(sortedItems);
        constructList();
    };

    const handleInputSubmit = async (id: string, name: string, value: any) => {
        const index = bannerList.findIndex((banner: any) => banner._id === id);

        // if((name === 'visibleStartDate' || name === 'visibleEndDate') && value) {
        //     try{
        //         value = new Date(value);
        //     }catch (e) {
        //
        //     }
        // }
        console.log(id, name, value, index);
        bannerList[index][name] = value;
        console.log(id, name, value);
        constructList();
        await putBanner(id, { [name]: value });
        console.log(JSON.stringify({ [name]: value }));

        // setTimeout(()=> {
            if (name === 'slimImageUrl' || name === 'homeImageUrl' || name === 'iconImageUrl') {
                const element = document.getElementsByClassName(`${name}-${id}`)[0];
                if (element instanceof HTMLImageElement) {
                    console.log('String');
                    element.src = value;
                }
            }
        // }, 1000);
    };

    const handleNewsletterChange = async (id: string, name: string, value: any) => {
        console.log('selectedList: ', value);
        const index = bannerList.findIndex((newsletter: any) => newsletter._id === id);
        const valueToSave = value.map((category: any) => category._id);
        bannerList[index][name] = valueToSave;
        constructList();
        await putNewsletter(id, { [name]: valueToSave });
    };

    const handleAddBanner = async() => {
        console.log('newBannerTitle', newBannerTitle);

        if(!newBannerTitle){
            return;
        }

         await postSlimBanner({ title: newBannerTitle, order: 999, visible: false });

        setTimeout(()=> {
            constructList(true);
        }, 500);

        setDefaultValue('');

        //InputBase값 초기화
        const input = document.getElementById('input_new_banner_title');
        if(input instanceof HTMLInputElement){
            input.value = '';

        }

        //배너가 추가되었습니다. alert
        alert('배너가 추가되었습니다.');
    }

    // const [targetId, setTargetId] = React.useState('');
    // const [password, setPassword] = React.useState('');
    // const [targetMetaGroup, setTargetMetaGroup] = React.useState({_id: '', title: '', metaIds: []});
    // const [open, setOpen] = React.useState(false);
    // const [isSaving, setIsSaving] = React.useState(false);

    // let visible = '';

    // const changeFilter = () => {
    //
    //     const visibleSelect = document.getElementById('input_search');
    //     if(visibleSelect instanceof HTMLSelectElement){
    //         visible = visibleSelect.value;
    //     }
    //
    //     setTimeout(()=> {
    //         constructList(true);
    //     }, 100);
    //
    // // };
    //
    // const submit = (e: any) => {
    //     console.log(e.keyCode);
    //
    //     if (e.keyCode === 13) {
    //         e.preventDefault();
    //         changeFilter();
    //         // e.stopImmediatePropagation();
    //         //Do your stuff...
    //     }
    //
    //     // if(e.keyCode===13){
    //     //   changePage(1);
    //     //   return false;
    //     // }
    // }

    type Option = {
        label: string;
        value: string;
    };

    const options: Option[] = [
        { label: "전체", value: "" },
        { label: "노출", value: "true" },
        { label: "비노출", value: "false" },
    ];

    const [selectedOption, setSelectedOption] = useState<Option>(options[0]);

    const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const optionValue = event.target.value;
        const option = options.find((opt) => opt.value === optionValue);
        if (option) {
            visible = optionValue;
            setSelectedOption(option);
            // constructList(true)
                setTimeout(()=> {
                    constructList(true);
                }, 100);
        }
    };

    return (
        <div>
            <h1>슬림배너 관리</h1>
            <div style={{display: 'flex', justifyContent: 'flex-start', marginBottom: '10px'}}>
                <Paper
                    component="form"
                    sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
                >
                    <Typography>
                        신규 배너 추가
                    </Typography>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    {/*input id를 input_new_banner_title로 설정*/}
                    {/*input 에서 enter key를 누르면 handleAddBanner가 실행되도록 설정*/}

                    <InputBase
                        id="input_new_banner_title"
                        sx={{ ml: 1, flex: 1 }}
                        // value={defaultValue}
                        placeholder="배너명"
                        inputProps={{ "aria-label": "new category id" }}
                        onChange={event=>{                                 //adding the onChange event
                            setNewBannerTitle(event.target.value)
                        }}
                    />
                    <IconButton sx={{ p: "10px" }} aria-label="add">
                        <AddIcon onClick={handleAddBanner} />
                    </IconButton>
                </Paper>
            </div>
            <div>
                <label htmlFor="select-option">노출여부:</label>
                <select id="select-option" onChange={handleOptionChange} value={selectedOption.value}>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>

            {/*이메일&닉네임 : <input id='select_visible' type='text' defaultValue={search} onKeyUp={(e)=>submit(e)} />*/}
            {/*<button onClick={e => changeFilter()} style={{marginLeft: '10px'}}> 적용</button>*/}
            <div className="uploader-wrapper" ref={divRef} style={{display: 'none'}}>
                <FileUploader fileUploaded={fileUploaded}
                              fileGot={fileGot}
                              accept='image/*'
                              multiple={false}
                    // locale={locale}
                              name={name}
                              url={url}
                              method={method}
                              beforeRequest= {beforeRequest}
                >
                </FileUploader>
            </div>

            <MainList
                onSortToggle={sort}
                onInputSubmit={handleInputSubmit}
                columnInfo={columnInfo}
                listData={items}
                multipleSelectList={metadataList}
                onMultipleSelectCheckboxChange={handleNewsletterChange}
                buttons={[]}
                bannerImageUpload={bannerImageUpload}
                // handleOpen={handleOpen}
            />
        </div>
    );
};
