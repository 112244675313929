import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { Dashboard } from './components/Dashboard';
import LoginButton from './components/LoginButton';
import { NewsletterList } from './components/NewsletterList';
import { Temp } from './components/Temp';
import { UserList } from './components/UserList';
import { UserStatistics } from './components/UserStatistics';
import { UserTypeStatistics } from './components/UserTypeStatistics';
import { ViewStatistics } from './components/ViewStatistics';
import { NewsletterStatistics } from './components/NewsletterStatistics';
import { CurationList } from './components/CurationList';
import { BannerList } from './components/BannerList';
import { MainBannerList } from './components/MainBannerList';
import { AttendanceBannerList } from './components/AttendanceBannerList';
import { NewsletterBannerList } from './components/NewsletterBannerList';
import { CategoryList } from './components/CategoryList';
import { CurationCategoryList } from './components/CurationCategoryList';
import {PushNotification} from "./components/pushNotification/PushNotification";
import {PushNotificationAdd} from "./components/pushNotification/PushNotificationAdd";
import {PushNotificationEdit} from "./components/pushNotification/PushNotificationEdit";

//StickerList, StickerCategoryList
import { StickerList } from './components/StickerList';
import { StickerCategoryList } from './components/StickerCategoryList';
import CouponGift from "./components/CouponGift";
import PremiumSettlement from "./components/PremiumSettlement";
import MembershipPayment from "./components/MembershipPayment";

const ProtectedRoute = (routeProps: RouteProps) => {
  const { user, isAuthenticated } = useAuth0();

  if (!(user && isAuthenticated && user["https://admin.heybunny.io/roles"].includes("admin"))) {
    window.location.href = "https://app.heybunny.io";
  }
  if (isAuthenticated) {
    return <Route {...routeProps} />;
  } else {
    return <LoginButton />;
  }
};

export const routes = () => {
  return (
    <Switch>
      <ProtectedRoute exact path="/">
        <Dashboard />
      </ProtectedRoute>
      <ProtectedRoute exact path="/newsletters">
        <NewsletterList />
      </ProtectedRoute>
      <ProtectedRoute exact path="/users">
        <UserList />
      </ProtectedRoute>
      <ProtectedRoute exact path="/user-stats">
        <UserStatistics />
      </ProtectedRoute>
      <ProtectedRoute exact path="/usertype-stats">
        <UserTypeStatistics />
      </ProtectedRoute>
      <ProtectedRoute exact path="/view-stats">
        <ViewStatistics />
      </ProtectedRoute>
      <ProtectedRoute exact path="/newsletter-stats">
        <NewsletterStatistics />
      </ProtectedRoute>
      <ProtectedRoute exact path="/curations">
        <CurationList />
      </ProtectedRoute>
      <ProtectedRoute exact path="/banners">
        <BannerList />
      </ProtectedRoute>
      <ProtectedRoute exact path="/main-banners">
        <MainBannerList />
      </ProtectedRoute>
      <ProtectedRoute exact path="/attendance-banners">
        <AttendanceBannerList />
      </ProtectedRoute>
      <ProtectedRoute exact path="/newsletter-banners">
        <NewsletterBannerList />
      </ProtectedRoute>
      <ProtectedRoute exact path="/stickers">
        <StickerList />
      </ProtectedRoute>
      <ProtectedRoute exact path="/categories">
        <CategoryList />
      </ProtectedRoute>
      <ProtectedRoute exact path="/curation-categories">
        <CurationCategoryList />
      </ProtectedRoute>
      <ProtectedRoute exact path="/sticker-categories">
        <StickerCategoryList />
      </ProtectedRoute>
      <ProtectedRoute exact path="/push-notifications">
        <PushNotification />
      </ProtectedRoute>
      <ProtectedRoute exact path="/push-notifications-post">
        <PushNotificationAdd />
      </ProtectedRoute>
      <ProtectedRoute exact path="/push-notifications/:id">
        <PushNotificationAdd />
      </ProtectedRoute>
      <ProtectedRoute exact path="/coupon-gift">
        <CouponGift />
      </ProtectedRoute>
      <ProtectedRoute exact path="/premium-settlement">
        <PremiumSettlement />
      </ProtectedRoute>
      <ProtectedRoute exact path="/membership">
        <MembershipPayment />
      </ProtectedRoute>
      <Route exact path="/temp">
        <Temp />
      </Route>
    </Switch>
  );
};
