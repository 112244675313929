import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './App.css';
import { Header } from './components/Header';
import { LeftDrawer } from './components/LeftDrawer';
import { MainContent } from './components/Main';
import { ExtendedTheme } from './theme';
import {accessToken, setAccessToken} from './utils/auth';

declare const window: any;
declare const email : string;

const useStyles = makeStyles((theme: ExtendedTheme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      width: `calc(100% - ${theme.drawerWidth}px)`,
    },
  })
);

function App() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { isLoading, user, loginWithRedirect, isAuthenticated, getAccessTokenSilently } = useAuth0();

  // const test = () =>{
  //   window.ChannelIO('boot', {
  //     "pluginKey": "5a48dd08-b4eb-4389-9194-5d27b9c258e5"
  //   });
  // }

  const [userInfo, setUserInfo] = useState<any>(null);

  useEffect(() => {
    if (!user) return;
    setUserInfo(user);
  }, [user]);

  const toggleDrawerOpen = () => {
    setOpen(!open);
  };
  useEffect(() => {
    // if (user || userInfo) return;
    // (async () => {
    //   try {
    //     console.log("???get??", getAccessTokenSilently);
    //     const token = await getAccessTokenSilently({
    //       useRefreshTokens: true,
    //     });
    //     console.log("token: ", token);
    //     if (token) setAccessToken(token);
    //   } catch (e) {
    //     console.error(e);
    //   }
    // })();
    // // alert(JSON.stringify(user.email));
    //
    // let email:string = 'YOUR_NAME';
    // // email = user.toString();
    //
    // // Boot Channel as a registered user
    //
    // window.ChannelIO('boot', {
    //   "pluginKey": "5a48dd08-b4eb-4389-9194-5d27b9c258e5",
    //   "profile": {
    //     "name": email, //fill with user name
    //     "mobileNumber": "YOUR_USER_MOBILE_NUMBER", //fill with user phone number
    //     "CUSTOM_VALUE_1": "VALUE_1", //any other custom meta data
    //     "CUSTOM_VALUE_2": "VALUE_2"
    //   }
    // });
    // // //
    // // ChannelIO.boot({
    // //   "pluginKey": "YOUR_PLUGIN_KEY", //please fill with your plugin key
    // //   "profile": {
    // //     "name": "YOUR_USER_NAME", //fill with user name
    // //     "mobileNumber": "YOUR_USER_MOBILE_NUMBER", //fill with user phone number
    // //     "CUSTOM_VALUE_1": "VALUE_1", //any other custom meta data
    // //     "CUSTOM_VALUE_2": "VALUE_2"
    // //   }
    // // });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (user || userInfo) return;

    // (async () => {
    //   try {
    //     console.log("???get??", getAccessTokenSilently);
    //     const token = await getAccessTokenSilently({
    //       useRefreshTokens: true,
    //     });
    //     if (token) setAccessToken(token);
    //     console.log("token: ", token);
    //   } catch (e) {
    //     console.error(e);
    //   }
    // })();
  }, [user, userInfo]);

  const getToken = async () => {
    return await getAccessTokenSilently({
      useRefreshTokens: true,
    });
  };

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (!(user && isAuthenticated)) {
    setTimeout(() => loginWithRedirect({}), 3000);
    return <h1>Redirecting to login...</h1>;
  }

  if (!user["https://admin.heybunny.io/roles"].includes("admin")) {
    window.location.href = "https://app.heybunny.io";
    return <h1>Redirecting...</h1>;
  }

  return (
    <div className="App">
      <div className={classes.root}>
        <Header onToggleClick={toggleDrawerOpen} isOpen={open} />
        <LeftDrawer onToggleClick={toggleDrawerOpen} isOpen={open} userInfo={userInfo} />
        <MainContent />
      </div>
    </div>
  );
}

export default App;
