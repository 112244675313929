import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Box,
    Switch
} from "@mui/material";
import { useState, useEffect, FC } from "react";
// import { fetchContacts, deleteContact } from "../../service/api";
import {getPushNotification, getPushNotificationList, putPushNotification} from '../../utils/api';
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";

const tablHeadStyles = {
    fontSize: "15px",
    fontWeight: 800,
    color: "#fff",
};

const PushNotificationTable: FC = () => {
    moment.tz.setDefault("Asia/Seoul");
    const history = useHistory();

    const [notifications, setNotifications] = useState<any[]>([]);

    const getPushNotifications = async () => {
        const resp = await getPushNotificationList({});
        console.log('res', resp.pushNotificationList);
        setNotifications(resp.pushNotificationList);
    };

    useEffect(() => {
        getPushNotifications();
    }, []);

    const deleteNotification = async (id: any) => {
        // await deleteContact(id);
        // getPushNotifications();
    };

    const handleChange = async (event: any, id: any) => {
        console.log('event', event);
        //target.checked
        // console.log(event.target.checked)
        // event.target.checked = false;

        const changeNotifications: any[] = notifications.map((notification) => {
            if (notification._id === id) {
                notification.activated = !notification.activated;

            }
            return notification;
        });

        const notification = changeNotifications.find((notification) => notification._id === id);
        await putPushNotification(id, {activated: notification.activated});

        setNotifications(changeNotifications);

    }

    return (
        <TableContainer component={Paper} sx={{ my: 7 }} style={{width: '100%', minWidth: '2000px', marginTop: '0'}}>
            <Table>
                <TableHead sx={{ backgroundColor: "#2A6AF9" }}>
                    <TableRow>
                        <TableCell style={tablHeadStyles}>Id</TableCell>
                        <TableCell style={tablHeadStyles}>작업명</TableCell>
                        <TableCell style={tablHeadStyles}>대상(사용자id)</TableCell>
                        <TableCell style={tablHeadStyles}>대상(사용자유형)</TableCell>
                        <TableCell style={tablHeadStyles}>발송일시</TableCell>
                        <TableCell style={tablHeadStyles}>푸시유형</TableCell>
                        <TableCell style={tablHeadStyles}>제목</TableCell>
                        <TableCell style={tablHeadStyles}>내용</TableCell>
                        <TableCell style={tablHeadStyles}>컨텐츠id</TableCell>
                        <TableCell style={tablHeadStyles}>진행상태</TableCell>
                        <TableCell style={tablHeadStyles}>대상건수</TableCell>
                        <TableCell style={tablHeadStyles}>처리건수</TableCell>
                        <TableCell style={tablHeadStyles}>등록일시</TableCell>
                        <TableCell style={tablHeadStyles}>업데이트일시</TableCell>
                        <TableCell style={tablHeadStyles}>활성여부</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {notifications.map((notification, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell>{notification._id}</TableCell>
                                <TableCell sx={{ textTransform: "capitalize" }}>
                                    {notification.taskName}
                                </TableCell>
                                <TableCell>{notification.targetUserEmails}</TableCell>
                                <TableCell>{notification.targetUserTypes.join(',')}</TableCell>
                                <TableCell>{moment(notification.targetDate).format('YYYY.MM.DD HH:mm:ss') }</TableCell>
                                <TableCell>{notification.pushType}</TableCell>
                                <TableCell>{notification.title}</TableCell>
                                <TableCell>{notification.content}</TableCell>
                                <TableCell>{notification.contentId}</TableCell>
                                <TableCell>{notification.status}</TableCell>
                                <TableCell>{notification.targetCount}</TableCell>
                                <TableCell>{notification.resultCount}</TableCell>
                                <TableCell>{
                                    // YYYY-MM-DD HH:mm:ss
                                    // date to string
                                    moment(notification.createdAt).format('YYYY.MM.DD HH:mm')

                                    // notification.createdAt
                                }</TableCell>
                                <TableCell>{
                                    moment(notification.updatedAt).format('YYYY.MM.DD HH:mm')
                                    // notification.updatedAt
                                }</TableCell>
                                <TableCell>
                                    {/*{JSON.stringify(notification.activated)}*/}
                                    <Switch checked={notification.activated}
                                            onChange={(event) => handleChange(event, notification._id)}
                                            inputProps={{ 'aria-label': 'controlled' }} />

                                </TableCell>

                                {/*<TableCell sx={{ textTransform: "capitalize" }}>*/}
                                {/*    {notification.city}*/}
                                {/*</TableCell>*/}
                                <TableCell>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                        }}
                                    >
                                        <IconButton
                                            onClick={() =>
                                                history.push(`/push-notifications/${notification._id}`)
                                            }
                                        >
                                            <BorderColorRoundedIcon
                                                fontSize="small"
                                                sx={{
                                                    color: "#6B7280",
                                                    mr: {
                                                        xs: 2,
                                                        md: 0,
                                                    },
                                                }}
                                            />
                                        </IconButton>
                                        <IconButton onClick={() => deleteNotification(notification._id)}>
                                            <CancelSharpIcon
                                                fontSize="small"
                                                sx={{ color: "#DA6868" }}
                                            />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PushNotificationTable;
