import { useState, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { setAccessToken } from "../utils/auth";
import { updateCoupon, deleteCoupon, getCouponGiftList, postCoupon } from "../utils/api";
import { CouponDraw } from "../utils/api.d";
import moment from "moment-timezone";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Button, FormControlLabel, IconButton, Switch } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/ko";
import dayjs, { Dayjs } from "dayjs";

import CloseIcon from "@mui/icons-material/Close";
import UpIcon from "@mui/icons-material/KeyboardArrowUp";
import DownIcon from "@mui/icons-material/KeyboardArrowDown";
import { BizMoney } from "./BizMoney";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

interface Data {
  seqNo: number;
  goodsCode: string;
  goodsName: string;
  goodsImgS: string;
  totalQuantity: number;
  requiredPopcorn: number;
  consumedQuantify: number;
  disabled: boolean;
  manage: boolean;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: any, b: any) => number {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  align?: "right" | "left" | "center";
}

const headCells: readonly HeadCell[] = [
  {
    id: "seqNo",
    disablePadding: true,
    align: "center",
    label: "순서",
  },
  {
    id: "goodsCode",
    disablePadding: false,
    label: "상품 코드",
  },
  {
    id: "goodsName",
    disablePadding: false,
    label: "상품 이름",
  },
  {
    id: "goodsImgS",
    disablePadding: false,
    align: "center",
    label: "상품 이미지",
  },
  {
    id: "totalQuantity",
    disablePadding: false,
    label: "총 수량",
  },
  {
    id: "requiredPopcorn",
    disablePadding: false,
    label: "소비 팝콘 수",
  },
  {
    id: "consumedQuantify",
    disablePadding: false,
    label: "당첨 수량",
  },
  {
    id: "disabled",
    disablePadding: false,
    align: "center",
    label: "적용 여부",
  },
  {
    id: "manage",
    disablePadding: false,
    align: "center",
    label: "정보 관리",
  },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  targetYearMonth: string;
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, targetYearMonth } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const heads = moment().format("YYYYMM") > targetYearMonth ? headCells.slice(0, 7) : headCells;
  const currentMonth = moment().format("YYYYMM") === targetYearMonth;

  return (
    <TableHead>
      <TableRow>
        {heads.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align || "left"} padding={"normal"} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              {currentMonth && headCell.id === "disabled" ? "활성 여부" : headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function CouponGift() {
  const { getAccessTokenSilently } = useAuth0();

  const [list, setList] = useState<
    {
      targetYearMonth: string;
      goods: CouponDraw[];
    }[]
  >([]);

  useEffect(() => {
    constructUserStat();
  }, []);

  const constructUserStat = async () => {
    const token = await getAccessTokenSilently();
    setAccessToken(token);

    const list = await getCouponGiftList();
    setList(list.couponDrawList);
  };

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("seqNo");

  const [newMonth, setNewMonth] = useState<Dayjs | null>(null);
  const [url, setUrl] = useState<string>("");
  const [available, setAvailable] = useState<boolean>(true);
  const [addYearMonth, setAddYearMonth] = useState<string>("");

  const [open, setOpen] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  useEffect(() => {
    if (!available) {
      setAvailable(true);
    }
  }, [url]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setUrl("");
    setAddYearMonth("");
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const onAddMonth = () => {
    if (!newMonth) return;

    const existMonths = list.map((item) => item.targetYearMonth);
    if (existMonths.includes(newMonth?.format("YYYYMM"))) {
      alert("이미 존재하는 월입니다.");
      return;
    }

    if (newMonth.format("YYYYMM") < moment().format("YYYYMM")) {
      alert("오늘 날짜보다 이전 월은 등록할 수 없습니다.");
      return;
    }

    setList((prev) => [{ targetYearMonth: newMonth?.format("YYYYMM"), goods: [] }, ...prev]);
  };

  const checkGoodsInfo = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (!url) return;

    setSubmiting(true);

    try {
      const goodsCode = url.split("goodsCode=")[1];
      const idx = list.findIndex((item) => item.targetYearMonth === addYearMonth);
      const currentGoods = list[idx]?.goods || [];
      if (currentGoods?.some((goods) => goods.goodsCode === goodsCode)) {
        alert("이미 등록된 상품입니다.");
        return;
      }

      const res = await postCoupon(goodsCode, addYearMonth || "");

      if (res.result?.goodsDetail) {
        setAvailable(true);

        const detail = res.result.goodsDetail;

        const newGoods = [
          ...currentGoods,
          {
            ...detail,
            _id: res.result.drawId,
            seqNo: currentGoods.length + 1,
            totalQuantity: 0,
            consumedQuantify: 0,
            requiredPopcorn: 0,
          },
        ];
        list[idx].goods = newGoods;

        setList(list);

        handleClose();
        return;
      } else {
        setAvailable(false);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmiting(false);
    }
  };

  const preChangeSeq = async (idx: number, seq1: number, seq2: number) => {
    const currentGoods = [...list[idx].goods];

    const { smallSeq, bigSeq } = seq1 < seq2 ? { smallSeq: seq1, bigSeq: seq2 } : { smallSeq: seq2, bigSeq: seq1 };

    const smallSeqIdx = currentGoods.findIndex((item) => item.seqNo === smallSeq);
    const bigSeqIdx = currentGoods.findIndex((item) => item.seqNo === bigSeq);
    if (smallSeqIdx === -1 || bigSeqIdx === -1) return;

    currentGoods[smallSeqIdx].seqNo = bigSeq;
    currentGoods[bigSeqIdx].seqNo = smallSeq;

    // currentGoods.splice(smallSeqIdx, 2, currentGoods[smallSeqIdx], currentGoods[bigSeqIdx]);

    setList((prev) => {
      const newList = [...prev];
      newList[idx].goods = currentGoods;
      return newList;
    });
  };

  const preChangeQuantity = async ({
    idx,
    itemIdx,
    totalQuantity,
    requiredPopcorn,
    consumedQuantify,
  }: {
    idx: number;
    itemIdx: number;
    totalQuantity: number;
    requiredPopcorn: number;
    consumedQuantify: number;
  }) => {
    const currentGoods = [...list[idx].goods];

    currentGoods[itemIdx].totalQuantity = totalQuantity;
    currentGoods[itemIdx].requiredPopcorn = requiredPopcorn;
    currentGoods[itemIdx].consumedQuantify = consumedQuantify;

    setList((prev) => {
      const newList = [...prev];
      newList[idx].goods = currentGoods;
      return newList;
    });
  };

  const preDeleteListItem = async (idx: number, itemIdx: number) => {
    const currentGoods = [...list[idx].goods];
    currentGoods.splice(itemIdx, 1);

    for (let i = itemIdx; i < currentGoods.length; i++) {
      currentGoods[i].seqNo -= 1;
    }

    setList((prev) => {
      const newList = [...prev];
      newList[idx].goods = currentGoods;
      return newList;
    });
  };

  const preChangeDisabled = async (idx: number, itemIdx: number, disabled: boolean) => {
    const currentGoods = [...list[idx].goods];
    currentGoods[itemIdx].disabled = disabled;

    setList((prev) => {
      const newList = [...prev];
      newList[idx].goods = currentGoods;
      return newList;
    });
  };

  return (
    <div style={{ paddingTop: "30px" }} className="premium-settlement">
      <div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
          <DatePicker
            views={["month", "year"]}
            value={newMonth}
            onChange={(newValue) => {
              setNewMonth(newValue);
            }}
            renderInput={(params) => <TextField {...params} size="small" style={{ width: "180px", marginRight: "10px" }} />}
          />
        </LocalizationProvider>
        <Button onClick={onAddMonth} variant="contained" color="primary">
          신규 등록
        </Button>
        <BizMoney />
      </div>

      <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <Box sx={{ ...style, display: "flex", flexDirection: "column", gap: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h2 id="parent-modal-title" style={{ margin: 0 }}>
              상품 추가
            </h2>
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <TextField
              id="standard-helperText"
              label="기프티쇼비즈 상품 URL 입력"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              variant="standard"
              style={{ flex: 1 }}
            />
            <Button variant="contained" color="default" onClick={checkGoodsInfo} disabled={!url || submiting}>
              등록
            </Button>
          </div>

          {!available && <h3 style={{ color: "red" }}>등록할 수 없는 상품입니다.</h3>}
        </Box>
      </Modal>

      {(list || []).map((item, i) => {
        let visibleRows = item.goods
          .sort(getComparator(order, orderBy))
          .flatMap((goods) => ({ ...goods, disabled: goods.hasOwnProperty("disabled") ? goods.disabled : false }));

        return (
          <Accordion defaultExpanded={i === 0} key={i} style={{ marginBottom: "10px", width: "1400px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ fontWeight: "700", borderBottom: "1px solid #e0e0e0" }}
            >
              <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
                {moment(item.targetYearMonth).format("YYYY년 MM월")}
                {moment().format("YYYYMM") < item.targetYearMonth ? " - 예정" : moment().format("YYYYMM") === item.targetYearMonth ? " - 진행 중" : " - 완료"}
              </Typography>
            </AccordionSummary>
            {moment().format("YYYYMM") <= item.targetYearMonth && (
              <div style={{ display: "flex", flexDirection: "column", gap: "4px", padding: "4px 16px", alignItems: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setAddYearMonth(item.targetYearMonth);
                    handleOpen();
                  }}
                  style={{ width: "100px" }}
                >
                  상품 추가
                </Button>
              </div>
            )}
            <AccordionDetails>
              <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "100%", mb: 2, fontSize: "12px" }}>
                  <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                      <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} targetYearMonth={item.targetYearMonth} />
                      <TableBody>
                        {visibleRows.map((row, index) => {
                          return (
                            <CouponItem
                              key={row._id || new Date().getTime()}
                              row={row}
                              targetYearMonth={item.targetYearMonth}
                              seqUp={index !== 0}
                              seqDown={index !== visibleRows.length - 1}
                              seqChange={(num1, num2) => preChangeSeq(i, num1, num2)}
                              quantityChange={(param) => preChangeQuantity({ idx: i, itemIdx: index, ...param })}
                              deleteListItem={() => preDeleteListItem(i, index)}
                              disabledChange={(disabled) => preChangeDisabled(i, index, disabled)}
                            />
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

const CouponItem = ({
  row,
  targetYearMonth,
  seqUp,
  seqDown,
  seqChange,
  quantityChange,
  deleteListItem,
  disabledChange,
}: {
  row: CouponDraw;
  targetYearMonth: string;
  seqUp: boolean;
  seqDown: boolean;
  seqChange: (seq1: number, seq2: number) => void;
  quantityChange: (param: { totalQuantity: number; requiredPopcorn: number; consumedQuantify: number }) => void;
  deleteListItem: () => void;
  disabledChange: (disabled: boolean) => void;
}) => {
  const [edit, setEdit] = useState(false);

  const [totalQuantity, setTotalQuantity] = useState<number>(row.totalQuantity);
  const [requiredPopcorn, setRequiredPopcorn] = useState<number>(row.requiredPopcorn);
  const [consumedQuantify, setConsumedQuantify] = useState<number>(row.consumedQuantify);

  const updateGoods = async (id: string) => {
    if (!edit) {
      setEdit(true);
    } else {
      if (totalQuantity < 0 || requiredPopcorn < 0 || consumedQuantify < 0) {
        alert("수량은 0 이상이어야 합니다.");
        return;
      }

      if (totalQuantity < consumedQuantify) {
        alert("당첨 수량은 총 수량보다 클 수 없습니다.");
        return;
      }

      if (requiredPopcorn <= 0) {
        alert("소비 팝콘 수는 1 이상이어야 합니다.");
        return;
      }

      quantityChange({ totalQuantity, requiredPopcorn, consumedQuantify });

      await updateCoupon(id, { totalQuantity, requiredPopcorn, consumedQuantify });
      setEdit(false);
      alert("입력한 내용으로\n경품 정보를 수정했습니다.");
    }
  };

  const deleteGoods = async (_id: string) => {
    if (window.confirm("삭제하시겠습니까?")) {
      deleteListItem();
      await deleteCoupon(_id);
    } else {
      console.log("취소");
      return;
    }
  };

  const changeSeq = async (isUp: boolean, id: string, seq: number) => {
    const chgSeq = isUp ? seq - 1 : seq + 1;
    seqChange(seq, chgSeq);

    if (isUp) await updateCoupon(id, { seqNo: [seq, chgSeq].join(","), targetYearMonth });
    else await updateCoupon(id, { seqNo: [seq, chgSeq].join(","), targetYearMonth });
  };

  const changeDisabled = async (id: string, disabled: boolean) => {
    disabledChange(disabled);
    await updateCoupon(id, { disabled });
  };
  const currentMonth = moment().format("YYYYMM") <= targetYearMonth;

  return (
    <TableRow hover role="checkbox" tabIndex={-1} sx={{ cursor: "pointer" }} style={{ color: row.disabled ? "#9E9E9E" : "black" }}>
      <TableCell component="th" scope="row" align="center">
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            {seqUp && !edit && (
              <IconButton onClick={() => changeSeq(true, row._id, row.seqNo)} size="small">
                <UpIcon />
              </IconButton>
            )}
            {row.seqNo}
            {seqDown && !edit && (
              <IconButton onClick={() => changeSeq(false, row._id, row.seqNo)} size="small">
                <DownIcon />
              </IconButton>
            )}
          </div>
          {/* <div>{row.seqNo}</div> */}
        </div>
      </TableCell>
      <TableCell component="th" scope="row">
        {row.goodsCode}
      </TableCell>
      <TableCell>{row.goodsName}</TableCell>
      <TableCell align="center">
        <img src={row.goodsImgS} alt={row.goodsName} style={{ maxWidth: "100px", maxHeight: "100px" }} />
      </TableCell>
      <TableCell align="right" width={140}>
        {edit ? (
          <TextField
            type="number"
            value={totalQuantity === 0 ? "" : totalQuantity}
            onChange={(e) => {
              if (totalQuantity && totalQuantity === 0) setTotalQuantity(Number(e.target.value.slice(1)));
              else setTotalQuantity(Number(e.target.value));
            }}
          />
        ) : (
          row.totalQuantity
        )}
      </TableCell>
      <TableCell align="right" width={140}>
        {edit ? (
          <TextField
            type="number"
            value={requiredPopcorn === 0 ? "" : requiredPopcorn}
            onChange={(e) => {
              if (requiredPopcorn && requiredPopcorn === 0) setRequiredPopcorn(Number(e.target.value.slice(1)));
              else setRequiredPopcorn(Number(e.target.value));
            }}
          />
        ) : (
          row.requiredPopcorn
        )}
      </TableCell>
      <TableCell align="right" width={140}>
        {edit ? (
          <TextField
            type="number"
            value={consumedQuantify === 0 ? "" : consumedQuantify}
            onChange={(e) => {
              if (consumedQuantify && consumedQuantify === 0) setConsumedQuantify(Number(e.target.value.slice(1)));
              else setConsumedQuantify(Number(e.target.value));
            }}
          />
        ) : (
          row.consumedQuantify
        )}
      </TableCell>
      {currentMonth && (
        <>
          <TableCell onClick={(e) => {}} align="center">
            {moment().format("YYYYMM") === targetYearMonth ? (
              <FormControlLabel
                style={{ marginRight: 0 }}
                control={
                  <Switch
                    disabled={edit}
                    checked={!row.disabled}
                    onChange={(e: any) => changeDisabled(row._id, !row.disabled)}
                    name="verified"
                    color="secondary"
                  />
                }
                label=""
              />
            ) : (
              <Button
                variant="contained"
                color="secondary"
                disabled={edit}
                style={{ background: edit ? "#E0E0E0" : "#f45454", color: "white" }}
                onClick={() => deleteGoods(row._id)}
              >
                삭제
              </Button>
            )}
          </TableCell>
          <TableCell align="center" onClick={(e) => e.stopPropagation()} width={180}>
            {edit ? (
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => {
                    setEdit(false);
                    setTotalQuantity(row.totalQuantity);
                    setRequiredPopcorn(row.requiredPopcorn);
                    setConsumedQuantify(row.consumedQuantify);
                  }}
                >
                  취소
                </Button>
                <Button variant="contained" color="primary" onClick={() => updateGoods(row._id)}>
                  적용
                </Button>
              </div>
            ) : (
              <Button variant="contained" color="primary" onClick={() => updateGoods(row._id)}>
                수정
              </Button>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};
