import { FC, useEffect } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { AddRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import PushNotificationTable from "./PushNotificationTable";

// const PushNotification: FC = () => {
export const PushNotification = () => {
    useEffect(() => {
        document.title = "Home | 푸시알림 발송 관리";
    }, []);

    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: {
                        sm: "space-between",
                    },
                    flexDirection: {
                        xs: "column",
                        sm: "row",
                    },
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        textAlign: "center",
                        mb: {
                            xs: 12,
                            sm: 12,
                        },
                    }}
                >
                    푸시알림 발송 리스트
                </Typography>
                <Button
                    component={Link}
                    to="/push-notifications-post"
                    variant="contained"
                    startIcon={<AddRounded />}
                    sx={{
                        mb: {
                            xs: 12,
                            sm: 12,
                        },
                    }}
                >
                    푸시발송 추가
                </Button>
            </Box>

            <PushNotificationTable />
        </Container>
    );
};

// export default PushNotification;
