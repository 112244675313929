import { Auth0Provider } from '@auth0/auth0-react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { theme } from './theme';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

// import * as firebase from "firebase/app";
// import "firebase/messaging";

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={{ ...theme }}>
      <Auth0Provider
        scope="read:current_user update:current_user_metadata read:roles"
        domain="accounts.heybunny.io"
        clientId="HCRFjGzAYTgBcJZEE8Jc4jgoQPyGwkBh"
        audience="https://api3.heybunny.io"
        redirectUri={window.location.origin}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Auth0Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const firebaseConfig = {
    apiKey: "AIzaSyD9Ca-XxbrWlJefeBt8wN9FnIWMQ_24WqY",
    authDomain: "heybunny-99b87.firebaseapp.com",
    projectId: "heybunny-99b87",
    storageBucket: "heybunny-99b87.appspot.com",
    messagingSenderId: "19827627313",
    appId: "1:19827627313:web:646110adecb6711e2d0c10",
    measurementId: "G-RDDNC9DEP6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const messaging = getMessaging(app);

getToken(messaging, { vapidKey: 'BH1HETDrOsmXmxGWCuepx_oHEZcvgB9saLKDu0hDg4JGXOgsijy1jJQQUmJG0sQvM87PGuR5e_YQk91-a9kJ8zM' }).then((currentToken) => {
    if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        console.log('currentToken', currentToken);
    } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
    }
}).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
});

onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // ...
});

