import { createStyles, makeStyles } from '@material-ui/core/styles';
import { routes } from '../routes';
import { ExtendedTheme } from '../theme';

const useStyles = makeStyles((theme: ExtendedTheme) =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  })
);

export const MainContent = () => {
  const classes = useStyles();
  return (
    <main className={classes.main}>
      <div className={classes.toolbar} />
      {routes()}
    </main>
  );
};
