import { useAuth0 } from '@auth0/auth0-react';
import { FormControlLabel, Switch, TextField } from '@material-ui/core';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import qs from 'qs';
import { RouteComponentProps } from 'react-router-dom';
import { FileUploader } from "file-uploader-react-component";
import {getCategoryList, putCategory, postCategory} from '../utils/api';
import { accessToken, setAccessToken } from '../utils/auth';
import { MainList } from './MainList';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const columnInfo = [
    {
        label: '노출순서',
        name: 'order',
        isAscending: true,
        isTextEditable: true,
        style: { minWidth: 110 },
    },
    {
        label: 'ID',
        name: '_id',
        style: { maxWidth: 220 },
        isAscending: false,
    },
    {
        label: 'Title',
        name: 'categoryName',
        isAscending: false,
        isTextEditable: true,
        style: { minWidth: 250 },
    },

    {
        label: '이모지',
        name: 'emoji',
        isAscending: false,
        isTextEditable: true,
        style: { minWidth: 150 },
    },
    {
        label: '',
        name: 'thumbnails-preview',
        isAscending: false,
        isTextEditable: false,
        style: { minWidth: 70 },
    },
    {
        label: '썸네일',
        name: 'thumbnails',
        isAscending: false,
        isTextEditable: true,
        style: { minWidth: 250 },
    },

    {
        label: '노출',
        name: 'visible',
        isAscending: false,
    },


];

let categoryList: any = [];
let metadataList: any = [];
export const CategoryList = () => {
    const [items, setItems] = useState<any>([]);
    const { getAccessTokenSilently } = useAuth0();

    // const [locale, setLocale] = React.useState<Locale | null>(null)
    const [name, setName] = React.useState('images');
    const [url, setUrl] = React.useState('https://mqnddbigxf.execute-api.ap-northeast-2.amazonaws.com/v1/upload');
    const [method, setMethod] = React.useState('POST');

    // const [page, setPage] = React.useState('1');
    // const [limit, setLimit] = React.useState('100');

    const [newCategoryID, setNewCategoryId] = useState('');
    const [defaultValue, setDefaultValue] = useState('');

    let page = '1';
    let limit = '1000';
    let search = '';

    const inputRef = useRef<HTMLInputElement>(null);
    const divRef = useRef<HTMLDivElement>(null);

    // const inputEl = useRef();
    //

    let selectedId = '';

    const constructList = async (isReload: boolean = false) => {
        if (isReload || categoryList.length === 0) {
            setAccessToken(await getAccessTokenSilently());
            const result = await getCategoryList({params: {page: page, limit: limit, search: search}});

            const newsletterCount = result.length;
            // console.log("newsletterCount", newsletterCount);

            categoryList = result.categoryList.map((newsletter: any, index: number) => {
                return { ...newsletter, orderNo: newsletterCount - index };
            });
            metadataList = result.metadataList;

        }

        setItems(
            categoryList.map((item: any, index: number) => {
                return {
                    ...item,
                    createdAt: moment(item.createdAt).format('YYYY.MM.DD HH:mm:ss'),
                    updatedAt: moment(item.updatedAt).format('YYYY.MM.DD HH:mm:ss'),
                };
            })
        );
    };

    useEffect(() => {
        constructList();
    }, []);


    const changePage = () => {

        const pageinput = document.getElementById('input_page_number');
        if(pageinput instanceof HTMLInputElement){
            page = pageinput.value;
        }


        const limitinput = document.getElementById('input_page_limit');
        if(limitinput instanceof HTMLInputElement){
            limit = limitinput.value;
        }

        const searchinput = document.getElementById('input_search');
        if(searchinput instanceof HTMLInputElement){
            search = searchinput.value;
        }

        setTimeout(()=> {
            constructList(true);
        }, 500);

    };

    const sort = (col: any) => {
        const index = columnInfo.findIndex((e) => e.name === col.name);
        columnInfo[index].isAscending = !columnInfo[index].isAscending;
        const sortedItems = [...categoryList].sort((a: any, b: any) => {
            if (typeof a[col.name] === 'number' || typeof a[col.name] === 'boolean') {
                return columnInfo[index].isAscending ? a[col.name] - b[col.name] : b[col.name] - a[col.name];
            }

            if (!a[col.name]) {
                a[col.name] = '';
            }
            if (!b[col.name]) {
                b[col.name] = '';
            }

            return columnInfo[index].isAscending ? a[col.name].localeCompare(b[col.name]) : b[col.name].localeCompare(a[col.name]);
        });
        categoryList = sortedItems;
        // setItems(sortedItems);
        constructList();
    };

    const handleInputSubmit = async (id: string, name: string, value: any) => {
        const index = categoryList.findIndex((category: any) => category._id === id);
        categoryList[index][name] = value;
        console.log(id, name, value);
        constructList();
        await putCategory(id, { [name]: value });
    };

    const handleAddCategory = async() => {
        console.log('categoryId', newCategoryID);

        if(!newCategoryID){
            return;
        }
        const index = categoryList.findIndex((category: any) => category._id === newCategoryID);

        if(index > -1){
            return;
        }

        await postCategory({ _id: newCategoryID, categoryName: 'undefined', thumbnails: '', order: 999, visible: false, emoji: '' });

        setTimeout(()=> {
            constructList(true);
        }, 500);

        setDefaultValue('');
    }

    // const changeNewCategoryId = (id: string) {
    //
    // }

    // const handleNewsletterChange = async (id: string, name: string, value: any) => {
    //     console.log('selectedList: ', value);
    //     const index = curationList.findIndex((newsletter: any) => newsletter._id === id);
    //     const valueToSave = value.map((category: any) => category._id);
    //     curationList[index][name] = valueToSave;
    //     constructList();
    //     await putNewsletter(id, { [name]: valueToSave });
    // };
    //
    // const [targetId, setTargetId] = React.useState('');
    // const [password, setPassword] = React.useState('');
    // const [targetMetaGroup, setTargetMetaGroup] = React.useState({_id: '', title: '', metaIds: []});
    // const [open, setOpen] = React.useState(false);
    // const [isSaving, setIsSaving] = React.useState(false);

    return (
        <div>
            <h1>아티클 카테고리 관리</h1>
            <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '10px'}}>
                <Paper
                    component="form"
                    sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
                >
                    <Typography>
                        신규 카테고리 추가
                    </Typography>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        // value={defaultValue}
                        placeholder="카테고리 ID"
                        inputProps={{ "aria-label": "new category id" }}
                        onChange={event=>{                                 //adding the onChange event
                            setNewCategoryId(event.target.value)
                        }}
                    />
                    <IconButton sx={{ p: "10px" }} aria-label="add">
                        <AddIcon onClick={handleAddCategory} />
                    </IconButton>
                </Paper>
            </div>

            <MainList
                onSortToggle={sort}
                onInputSubmit={handleInputSubmit}
                columnInfo={columnInfo}
                listData={items}
                // multipleSelectList={metadataList}
                // onMultipleSelectCheckboxChange={handleNewsletterChange}
                buttons={[]}
                // imageUpload={imageUpload}
                // handleOpen={handleOpen}
            />
        </div>
    );
};
